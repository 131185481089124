<app-sidenav-v2
[shortMenu]="true"

(buscar)="Filtrar($event)"
[placeholderSearch]="placeholderSearch"
>
        <div class="container">
            <div class="top">
                <div class="title">Vendors</div>
            </div>
        
            <div class="box">
                <div class="container-charging" *ngIf="load && VendorsFiltro.length === 0 ">
                    <div class="text">
                        <p>Cargando vendors...</p>
                    </div>
                    <div class="container-progress">
                        <img src="../../../../assets/images/letter-u.svg"/>
                        <!-- <mat-progress-bar mode="indeterminate"></mat-progress-bar> -->
                        <div class="progress">
                            <div class="progress-value"></div>
                          </div>
                    </div>
                  </div>
                  <div *ngIf="!loading">
                  <div *ngIf="perfil != 2" class="btns-banderas">
                        <button (click)="empresa='MX';BuscarVendors()" style="border: none; background: none; cursor: pointer;" >
                            <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/bandera-mex.jpg" alt="México" style="width: 30px; height: 22px;" [ngClass]="{'active': empresa === 'MX'}" >
                          </button>
                          <button (click)="empresa='PE';BuscarVendors()" style="border: none; background: none; cursor: pointer;" >
                            <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/bandera-peru.png" alt="Perú" style="width: 30px; height: 22px;" [ngClass]="{'active': empresa === 'PE'}" >
                          </button>
                    </div>
                 </div>
                <div class="table-vendors">
                    <div *ngIf="!loading">
                        <p *ngIf="!load && VendorsFiltro.length === 0">No hay vendors que mostrar</p>
                        <table class="table-vendors" [cellSpacing]="0" [cellPadding]="0" *ngIf="VendorsFiltro.length">
                            <thead>
                                <tr>
                                    <th style="max-width: 15px;">N.</th>
                                    <th>Nombre</th>
                                    <th>Correo electrónico</th>
                                    <th>Comercial</th>
                                    <th>País</th>
                                    <th style="max-width: 15px;">Tasa</th>
                                    <th>Opciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let Vendor of VendorsFiltro; let i = index">
                                    <td>{{i +1}}</td>
                                    <td>{{Vendor.fullname}}</td>
                                    <td>{{Vendor.email}}</td>
                                    <td>{{Vendor.comercial}}</td>
                                    <td>{{Vendor.country}}</td>
                                    <td style="text-align: right;">{{Vendor.tasa_mensual_financiamiento.ap12}}</td>
                                    <td id="options">
                                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                            <mat-icon>more_vert</mat-icon>
                                        </button>
                                        <mat-menu #menu="matMenu">
                                            <!-- <button class="btn-menu" routerLink="/vendors">
                                                <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/ver-producto.svg">
                                                <span>Ver vendor</span>
                                            </button> -->
                                            <button (click)="Editar(Vendor)" class="btn-menu">
                                                <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/editar-producto.svg">
                                                <span>Editar vendor</span>
                                            </button>
                                            <button (click)="ResetPassword(Vendor)" class="btn-menu">
                                                <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/resetearPassword.svg">
                                                <span>Restablecer Contraseña</span>
                                            </button>
                                            <button (click)="ActivarVendor(Vendor)" class="btn-menu"> <!--(click)="ActivarVendor(Vendor)"-->
                                                <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/duplicar-producto.svg">
                                                <span>Activar Vendor</span>
                                            </button>
                                            <button (click)="DesactivarVendor(Vendor)" class="btn-menu">
                                                <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/desactivar.svg">
                                                <span>Desactivar Vendor</span>
                                            </button>
                                            <!-- <button class="btn-menu" (click)="EliminarVendor(Vendor)">
                                                <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/eliminar-producto.svg">
                                                <span>Eliminar Vendor</span>
                                            </button> -->
                                        </mat-menu>
                                        <!-- <mat-icon matTooltip="Editar" (click)="Editar(Vendor)">edit</mat-icon> -->
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <app-socials-footer style="width: 100%;"></app-socials-footer>
        </div>
    </app-sidenav-v2>