import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Tramite } from "src/app/_models/tramite.model";
import { SolicitudService, UserService } from "src/app/_services";
import { Router } from "@angular/router";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MessageBoxComponent } from "src/app/_dialogs/message-box/message-box.component";
import { ReassignProcedureComponent } from "src/app/_dialogs/reassign-procedure/reassign-procedure.component";
import { PerfilService } from "src/app/_services/perfil.service";

import { DatePipe } from "@angular/common";
import { DecimalPipe } from "@angular/common";
import { flipCard, openCard } from "src/app/_animations/animations";
import { ModalContratoComponent } from "src/app/_dialogs/modal-contrato/modal-contrato.component";
import { ModalConfirmComponent } from "src/app/_dialogs/modal-confirm/modal-confirm.component";
import { NewModalMessageComponent } from "src/app/_dialogs/new-modal-message/new-modal-message.component";
import { DocumentosContratoRechazadosComponent } from "src/app/_dialogs/documentos-contrato-rechazados/documentos-contrato-rechazados.component";
import { switchMap, take, tap } from "rxjs/operators";
const moment = require("moment");

@Component({
  selector: "app-tramite-card",
  templateUrl: "./tramite-card.component.html",
  styleUrls: ["./tramite-card.component.scss"],
  animations: [openCard, flipCard],
})
export class TramiteCardComponent implements OnInit {
  @Input() tramite: Tramite = null;
  @Input() verSolicitud = true;
  @Input() verExpediente = true;
  @Input() verCotizacion = true;
  @Input() verCliente = true;
  @Input() verActivos = true;
  @Input() column = false;
  @Input() perfil = 0;
  @Input() id_usuario = 0;
  @Input() type = 0;
  @Input() resolucion = false;
  @Output() onChange = new EventEmitter

  PROCESO = [
    "REGISTRO",
    "COTIZACIÓN",
    "EXPEDIENTE",
    "CONFIRMACIÓN",
    "RESOLUCIÓN",
    "CONTRATO",
    "CONTRATO ENVIADO",
    "CONTRATO RECIBIDO",
    "CONTRATO ACTIVO",
    "EN COLOCACIÓN",
  ];
  STATUS = ["Pendiente", "En proceso", "Actualizar", "Completado"];
  statusContrato = {
    1: "Sin Asignar",
    2: "En proceso",
    3: "Re Firma",
    4: "Completado",
    5: "Cancelado"
  };
  resoluciones = {
    1: "Aprobado",
    2: "Aprobado condicional",
    3: "Info. adicional",
    4: "Rechazada",
    99: "Cerrada",
  };
  statusCoFi = {
    5: "Creado",
    6: "Enviado",
  };
  version: any;
  plazo: any;
  unread_comments = 0;
  hours_tramite: any;

  is_referred= false;
  valorReferido : String =null;

  puede_reasignar = false;

  excludedDates: Date[] = [];

  public DetalleCarta: any = {};
  private onlineOffline: boolean = navigator.onLine;
  showAllOptions: boolean = false;
  expandedCard: boolean = false;
  showComment: boolean = false;
  sideCardState: boolean = false;
  svgRotation: boolean = false;
  darkBackgroundActive = false;
  cardState = "normal";
  secondDivVisible = false;
  tiempoTooltip: string;
  horasPorProceso: any = 0;
  dias: any = 0;
  horasRestantes: any = 0;
  procesos = [
    "horas_solicitud",
    "horas_expediente",
    "horas_confirmacion",
    "horas_comite",
  ];
  public optSelected = 0;
  _fecha_compromiso = false;
  fecha_compromiso: Date = new Date();
  celula_seleccionada = "";
  celulas = ["1", "2", "3", "4"];
  celula_select = true;
  _semana_seleccionada = "";
  puede_semana_compromiso = false;

  tipo_activo = ["Nuevo", "Remanufacturado"];
  vendedor_nuevo = ["SI", "NO"];
  condiciones_pago = "";
  promocion = "";

  constructor(
    private router: Router,
    private userService: UserService,
    private dialog: MatDialog,
    private perfilService: PerfilService,
    private solicitudService: SolicitudService,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe
  ) {}

  ngOnInit(): void {
    this.unread_comments = Number(this.tramite.unread_comments);
    this.puede_reasignar =
      ([6, 8, 12,25,27].includes(this.perfil) && ![6, 5].includes(this.type)) ||
      [1, 10].includes(Number(this.id_usuario));
    this.puede_semana_compromiso =  ([6, 12, 10,27].includes(this.perfil)) || [3019,423].includes(Number(this.id_usuario))
    this.valorReferido=this.tramite.is_referred?' -R': null;
    //console.log("##::::-:::::###",this.tramite)
  }
  // dashboard/cliente/:folio/carga_documentos
  async Ver(type, comments = false) {
    console.log("VER", this.tramite.id_proceso, this.tramite.id_resolucion);
    this.userService.alertService.isOnline();
    const procesos = await this.userService.getStatusProceso(
      this.tramite.id,
      this.tramite.folio
    );
    localStorage.setItem(
      "client_details",
      JSON.stringify({
        activo_descripcion: this.tramite.activo_descripcion,
        activo_nombre: this.tramite.activo_nombre,
        cliente: this.tramite.nombre_cliente,
        cliente_nombre: this.tramite.cot_nombre_cliene,
        cliente_apellido_paterno: this.tramite.cot_apellido_paterno,
        cliente_apellido_materno: this.tramite.cot_apellido_materno,
        cliente_telefono: this.tramite.cliente_telefono,
        email: this.tramite.email,
        folio: this.tramite.folio,
        id_cliente: procesos.id_cliente,
        procesos: procesos.estauts.map(
          ({ id_proceso, nombre, estatus: status }) => ({
            id_proceso,
            nombre,
            status,
          })
        ),
        registro: this.tramite.registro,
        tipo_aval: procesos.tipo_aval,
        tipo_persona: procesos.tipo_persona,
        ultima_fecha: this.tramite.ultima_fecha,
        coficontactoid: this.tramite.coficontactoid,
        comercial: this.tramite.nombre_comercial,
        vendor: this.tramite.nombre_vendor,
        id_vendor: this.tramite.id_vendor
      })
    );

    console.log(
      "TRAMITE",
      this.tramite.id_resolucion,
      this.resolucion,
      this.tramite.status,
      this.tramite.id_proceso,
      this.tramite
    );

    switch (type) {
      case "cliente":
        if (this.verCliente) {
          if (comments) {
            localStorage.setItem("ver_comentarios", "true");
          }
          return this.router.navigate([
            "dashboard",
            "comercial",
            this.tramite.folio,
          ]);
        }
        break;
      case "solicitud":
        if (
          this.verSolicitud &&
          [2, 6, 3, 12, 4, 7, 8, 10, 13, 14, 15, 16, 17, 18, 19, 20, 25,26,27].includes(
            this.perfil
          )
        ) {
          localStorage.setItem("tipoAval", procesos.tipo_aval);
          localStorage.setItem("tipoPersona", procesos.tipo_persona);
          localStorage.setItem(
            "steps",
            JSON.stringify({
              datos_generales: 0,
              referencias: 0,
              aval: 0,
            })
          );
          return this.router.navigate(["consulta_solicitud"]);
        }
        break;
      case "expediente":
        if (
          this.verExpediente &&
          [2, 6, 3, 12, 4, 7, 8, 10, 13, 14, 15, 16, 17, 18, 19, 20,25,26,27].includes(
            this.perfil
          )
        ) {
          return this.router.navigate([
            "dashboard",
            "cliente",
            this.tramite.folio,
            "carga_documentos",
          ]);
        }
        break;
      case "cotizacion":
        if (
          this.verCotizacion &&
          [2, 6, 3, 12, 4, 7, 8, 10, 13, 14, 15, 16, 17, 18, 19, 20, 25,26,27].includes(
            this.perfil
          )
        ) {
          return this.router.navigate(["dashboard", "historial_cotizaciones"]);
        }
        break;
        case "activo":
        if (
          this.verActivos &&
          [2, 6, 3, 12, 4, 7, 8, 10, 13, 14, 15, 16, 17, 18, 19, 20,27].includes(
            this.perfil
          )
        ) {
          return this.router.navigateByUrl(`/admin/activos/${this.tramite.folio}`);

        }
        break;
    }
  }

  async cancelar(event, folio) {
    if (!this.userService.alertService.isOnline()) return;
  
    this.dialog.open(MessageBoxComponent, {
      width: "450px",
      data: {
        text: `¿Seguro que deseas cancelar<br>el trámite?`,
        type: "confirm-cot",
        confirm: () => {
          if (!this.userService.alertService.isOnline()) {
            return;
          }
  
          const user = JSON.parse(localStorage.getItem("currentUser"));
          
          // Llamar al servicio y manejar el flujo
          this.userService.CancelarTramite(folio, user.id).pipe(
            take(1), // Garantiza que solo se tome la primera emisión
            tap((res) => console.log("Respuesta del servicio:", res)), // Debug opcional
            switchMap(() =>
              this.dialog.open(MessageBoxComponent, {
                width: "350px",
                data: {
                  text: "Éxito",
                  text2: "Has cancelado el trámite",
                  type: "success",
                  icon: "success",
                },
              }).afterClosed() // Encadena el cierre del diálogo
            )
          ).subscribe(() => {
            this.onChange.emit(); // Acción después del cierre del segundo diálogo
          });
        },
        reject: () => console.log("Cancelación rechazada"),
      },
    });
  }
  

  async enviarContrato(folio) {
    const procesos = await this.userService.getStatusProceso(
      this.tramite.id,
      folio
    );
    localStorage.setItem(
      "client_details",
      JSON.stringify({
        activo_descripcion: this.tramite.activo_descripcion,
        activo_nombre: this.tramite.activo_nombre,
        cliente: this.tramite.nombre_cliente,
        cliente_nombre: this.tramite.cot_nombre_cliene,
        cliente_apellido_paterno: this.tramite.cot_apellido_paterno,
        cliente_apellido_materno: this.tramite.cot_apellido_materno,
        cliente_telefono: this.tramite.cliente_telefono,
        email: this.tramite.email,
        folio: this.tramite.folio,
        id_cliente: procesos.id_cliente,
        procesos: procesos.estauts.map(
          ({ id_proceso, nombre, estatus: status }) => ({
            id_proceso,
            nombre,
            status,
          })
        ),
        registro: this.tramite.registro,
        tipo_aval: procesos.tipo_aval,
        tipo_persona: procesos.tipo_persona,
        ultima_fecha: this.tramite.ultima_fecha,
        coficontactoid: this.tramite.coficontactoid,
        comercial: this.tramite.nombre_comercial,
        vendor: this.tramite.nombre_vendor,
      })
    );

    this.dialog.open(ModalContratoComponent, {
      width: "500px",
      height: "500px",
      data: {
        text: "Elige el formato de envío",
        icon: "https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/regresar-new.svg",
        btn: "Regresar",
        opt1: "Contrato físico",
        opt2: "Contrato digital",
        anexo: this.tramite.anexo,
        folio: this.tramite.folio,
        tramite: this.tramite,

        confirmopt: (val: number) => (this.optSelected = val),
      },
    });
  }

  async recibirContrato(folio) {
    const procesos = await this.userService.getStatusProceso(
      this.tramite.id,
      folio
    );
    localStorage.setItem(
      "client_details",
      JSON.stringify({
        activo_descripcion: this.tramite.activo_descripcion,
        activo_nombre: this.tramite.activo_nombre,
        cliente: this.tramite.nombre_cliente,
        cliente_nombre: this.tramite.cot_nombre_cliene,
        cliente_apellido_paterno: this.tramite.cot_apellido_paterno,
        cliente_apellido_materno: this.tramite.cot_apellido_materno,
        cliente_telefono: this.tramite.cliente_telefono,
        email: this.tramite.email,
        folio: this.tramite.folio,
        id_cliente: procesos.id_cliente,
        procesos: procesos.estauts.map(
          ({ id_proceso, nombre, estatus: status }) => ({
            id_proceso,
            nombre,
            status,
          })
        ),
        registro: this.tramite.registro,
        tipo_aval: procesos.tipo_aval,
        tipo_persona: procesos.tipo_persona,
        ultima_fecha: this.tramite.ultima_fecha,
        coficontactoid: this.tramite.coficontactoid,
        comercial: this.tramite.nombre_comercial,
        vendor: this.tramite.nombre_vendor,
      })
    );
    this.dialog.open(ModalConfirmComponent, {
      minWidth: "500px",
      data: {
        folio: folio,
        id: this.tramite.id,
        text1: "¿Se ha recibido el contrato?",
        aceptar: () => {
          this.userService.changeStatus(this.tramite.folio, 7, 3).subscribe(res => {
            this.userService
              .changeStatus(this.tramite.folio, 8, 0)
              .subscribe((res) => {
                  this.dialog.closeAll();
                  window.location.reload(); 
              });
            console.log(res)
             
          })
        }
      },
    });
  }

  async reasignar(folio) {
    if (!this.userService.alertService.isOnline()) return;
    const users = await this.perfilService.getUsuarios(
      Number(this.perfil),
      this.id_usuario
    );
    this.dialog.open(ReassignProcedureComponent, {
      width: "800px",
      data: {
        text: `Selecciona el usuario a reasignar:`,
        usuarios: users["children"], // Acceso limpio
        perfil: this.perfil,
        idUsr: this.id_usuario,
        type: "confirm-cot",
        confirm: (id_responsable: number) => {
          console.log("id_responsable", id_responsable);
    
          this.perfilService.reasgingProcedure(folio, id_responsable).pipe(
            take(1), 
            tap((res) => console.log("Respuesta del servicio:", res)), // Debug opcional
            switchMap(() =>
              this.dialog.open(MessageBoxComponent, {
                width: "550px",
                data: {
                  text: "Éxito",
                  text2: "Has reasignado el trámite",
                  type: "success",
                  icon: "success",
                },
              }).afterClosed() 
            )
          ).subscribe(() => {
            this.onChange.emit(); 
          });
        },
        reject: () => console.log("Nel"), // Acción para rechazar
      },
    });    
  }
  async carta(event: any, tramite: Tramite) {
    if (!this.userService.alertService.isOnline()) return;
    const proceso = await this.userService.getStatusProceso(
      tramite.id,
      tramite.folio
    );

    this.userService.ObtenerDetalleCarta(Number(tramite.folio)).subscribe(
      (res: any) => {
        this.DetalleCarta = res;
        this.SeleccionarCompraIVA();

        console.log("tramite :", tramite);
        const request = {
          folio: tramite.folio,
          id_solicitud: this.DetalleCarta.id_solicitud,
          id_cliente: proceso.id_cliente,
          params: {
            nombre_cliente: tramite.nombre_cliente,
            fecha_envio: this.transformDate(new Date(), "dd/MM/yyyy"),
            descripcion_activo: this.DetalleCarta.descripcion_activo,
            monto_arrendar: this.transformCifras(
              this.DetalleCarta.monto_arrendar
            ),
            plazo: this.DetalleCarta.plazo,
            comision: this.transformCifras(this.DetalleCarta.comision),
            renta_inicial: this.transformCifras(
              this.DetalleCarta.renta_inicial
            ),
            iva: this.transformCifras(this.DetalleCarta.iva),
            total_pago_inicial: this.transformCifras(
              this.DetalleCarta.total_pago_inicial
            ),
            renta_mensual: this.transformCifras(
              this.DetalleCarta.renta_mensual
            ),
            seguro: this.transformCifras(this.DetalleCarta.seguro),
            iva2: this.transformCifras(this.DetalleCarta.iva2),
            total_renta_inicial: this.transformCifras(
              this.DetalleCarta.total_renta_mensual
            ),
            mensualidades_pend: this.DetalleCarta.plazo,
            opcion_compra_IVA: this.transformCifras(
              this.DetalleCarta.opcionCompraIVA
            ),
          },
        };

        this.userService.informacionAdicionalCarta(request).subscribe(
          (res: any) => {
            window.open(res.url);
          },
          (err) => {
            console.log("error: ", err);
            this.dialog.open(MessageBoxComponent, {
              data: {
                text: "El servicio no está disponible.",
                text3: "Vuelve a intentarlo",
                type: "error",
                icon: "error",
              },
            });
          }
        );
      },
      (err) => {
        console.log(err);
      }
    );
  }

  public SeleccionarCompraIVA(): void {
    switch (this.DetalleCarta.plazo) {
      case 12:
        this.DetalleCarta.opcionCompraIVA = this.DetalleCarta.opcioncompraiva12;
        break;
      case 18:
        this.DetalleCarta.opcionCompraIVA = this.DetalleCarta.opcioncompraiva18;
        break;
      case 24:
        this.DetalleCarta.opcionCompraIVA = this.DetalleCarta.opcioncompraiva24;
        break;
      case 36:
        this.DetalleCarta.opcionCompraIVA = this.DetalleCarta.opcioncompraiva36;
        break;
      case 48:
        this.DetalleCarta.opcionCompraIVA = this.DetalleCarta.opcioncompraiva48;
        break;
      default:
        this.DetalleCarta.opcionCompraIVA = this.DetalleCarta.opcioncompraiva12;
    }
  }

  DiasTranscurridos(fecha_inicio) {
    let currentDate = new Date(fecha_inicio);
    const finalDate = new Date();
    let businessDays = 0;

    while (currentDate <= finalDate) {
      const dayOfWeek = currentDate.getDay();

      if (
        dayOfWeek !== 0 &&
        dayOfWeek !== 6 &&
        !this.isExcludedDate(currentDate, this.excludedDates)
      ) {
        businessDays++;
      }

      currentDate.setDate(currentDate.getDate() + 1);
    }
    return businessDays;
  }

  private isExcludedDate(date: Date, excludedDates: Date[]): boolean {
    return excludedDates.some((excludedDate) =>
      this.isSameDate(date, excludedDate)
    );
  }

  private isSameDate(date1: Date, date2: Date): boolean {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  public transformDate(date: any, format: any) {
    return this.datePipe.transform(date, format);
  }

  public transformCifras(num: any) {
    return this.decimalPipe.transform(num, "1.2-2");
  }

  CalcularFechaCompleta(fecha) {
    const fechaOriginalObjeto = new Date(fecha);

    const fechaActual: number = new Date().getTime();

    const fechaOriginalEnMilisegundos: number = fechaOriginalObjeto.getTime();

    const diferenciaEnMilisegundos: number =
      fechaActual - fechaOriginalEnMilisegundos;

    const dias: number = Math.floor(
      diferenciaEnMilisegundos / (24 * 60 * 60 * 1000)
    );

    return dias;
  }
  convertirHoras(horasTramite): string {
    horasTramite = Math.floor(horasTramite);

    const dias = Math.floor(horasTramite / 24);
    const horasRestantes = Math.floor(horasTramite % 24);

    if (dias > 0) {
      return `${dias} días ${horasRestantes} hrs `;
    } else {
      return `${horasRestantes} hrs`;
    }
  }
  convertirHorasPorTramite(idProceso, idResolucion) {
    const horasEnUnDia = 24;

    switch (idProceso) {
      case 1: // Solicitud
        this.horasPorProceso = this.tramite.horas_solicitud;
        break;
      case 2: // Expediente
        this.horasPorProceso = this.tramite.horas_expediente;
        break;
      case 3: // Confirmación
        this.horasPorProceso = this.tramite.horas_confirmacion;
        break;
      case 4: // Comité
        if (idResolucion == null) {
          this.horasPorProceso = this.tramite.horas_comite;
        } else {
          this.horasPorProceso = this.tramite.horas_resolucion;
        }
        break;
      case 5: // Contrato
        this.horasPorProceso = this.tramite.horas_contrato;
        break;
      case 6: // Contrato
        this.horasPorProceso = this.tramite.horas_contrato_enviado;
        break;
      case 7: // Contrato
        this.horasPorProceso = this.tramite.horas_contrato_recibido;
        break;
      case 8: // Contrato
        this.horasPorProceso = this.tramite.horas_contrato_activo;
        break;
      case 9: // Contrato
        this.horasPorProceso = this.tramite.horas_en_colocacion;
        break;
      default:
        this.horasPorProceso = this.tramite.horas_tramite_completo_exp_now;
        break;
    }

    // Calcula la cantidad de días y horas
    this.dias = Math.floor(this.horasPorProceso / 24);
    this.horasRestantes = Math.floor(this.horasPorProceso % 24);

    // Devuelve el resultado
    if (this.dias > 0) {
      return `${this.dias} días ${this.horasRestantes} horas`;
    } else {
      return `${this.horasRestantes} horas`;
    }
  }

  formatearFecha(fecha: any): string {
    return this.datePipe.transform(fecha, "dd/MM/yyyy");
  }

  formatearFechaNueva(fecha: any): string {
    const meses: string[] = [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
    ];
    const datePipe = new DatePipe("es");

    const fechaFormateada: string =
      datePipe.transform(fecha, "dd") +
      " de " +
      meses[parseInt(datePipe.transform(fecha, "MM")) - 1] +
      " " +
      datePipe.transform(fecha, "yyyy");

    return fechaFormateada;
  }

  toggleAllOptions() {
    this.showAllOptions = !this.showAllOptions;
  }

  expandCard() {
    this.expandedCard = !this.expandedCard;
  }

  showComments() {
    this.showComment = true;
  }
  onCardToggle() {
    this.sideCardState = !this.sideCardState;
    this.svgRotation = !this.svgRotation;
  }
  toggleFlipCard() {
    this.cardState = this.cardState === "normal" ? "flipped" : "normal";
    this.toggleDarkBackground();
    this.secondDivVisible = !this.secondDivVisible;
  }

  toggleDarkBackground() {
    this.darkBackgroundActive = !this.darkBackgroundActive;
  }
  infoTooltip(
    idProceso: number,
    idStatus: any,
    idResolucion: any,
    tramite: any
  ) {
    const horasEnUnDia = 24;
    let horasAcumuladas: number = 0;

    const lines: string[] = [];

    if (tramite.horas_expediente) {
      const dias = Math.floor(tramite.horas_expediente / horasEnUnDia);
      const horasRestantes = Math.floor(
        tramite.horas_expediente % horasEnUnDia
      );
      lines.push(
        `Expediente a Confirmación: ${dias} días ${horasRestantes} horas`
      );
    }

    if (tramite.horas_confirmacion && tramite.horas_comite) {
      const dias = Math.floor(tramite.horas_confirmacion / horasEnUnDia);
      const horasRestantes = Math.floor(
        tramite.horas_confirmacion % horasEnUnDia
      );
      lines.push(
        `Confirmación a Análisis Crédito: ${dias} días ${horasRestantes} horas`
      );
    }
    if (tramite.horas_comite && tramite.horas_resolucion) {
      const dias = Math.floor(tramite.horas_comite / horasEnUnDia);
      const horasRestantes = Math.floor(tramite.horas_comite % horasEnUnDia);
      lines.push(
        `Análisis de Crédito a Resolucion: ${dias} días ${horasRestantes} horas`
      );
    }

    return `Tiempo en el que demoró:\n${lines.join("\n")}`;
  }

  getHorasPorProceso(idResolucion: any, idStatus: any) {
    switch (idResolucion) {
      case 1:
        return this.tramite.horas_solicitud;
      case 2:
        return this.tramite.horas_expediente;
      case 3:
        if (idStatus == 3) {
          return this.tramite.horas_resolucion;
        } else {
          return this.tramite.horas_confirmacion;
        }
      case 4:
        return this.tramite.horas_comite;
      case 5:
        return this.tramite.horas_contrato;
      default:
        return this.tramite.horas_tramite_completo_exp_now;
    }
  }
  aceptarCelula() {
    console.log("CELULA SELECCIONADA Y GUARDADA", this.celula_seleccionada);
  }
  async seleccionarCelula() {
     const response: any = await this.solicitudService.ObtenerTramite(this.tramite.folio)
     const raw = JSON.parse(response.data[0].raw_cot)
     const plazo = raw.periods.map(periodo => periodo.period);
     const tipo_activo = ["Nuevo", "Remanufacturado"]
     const vendedor_nuevo = ["SI", "NO"]
     const condiciones_pago = ""
     const promocion = ""
     const description_activo = raw.product_description
     console.log("RAW",raw)
     console.log(plazo)

    this.dialog
      .open(NewModalMessageComponent, {
        width: "580px",
        height:"580px",
        data: {
          type: "confirm",
          icon_url:
            "https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/celula-group.png",
          text1: "Se asignará este folio a una célula, por favor confirma los siguientes datos",
          text2: "Activo nuevo o remanufacturado",
          option2: this.tipo_activo,
          text3: `Vendedor nuevo`,
          option3: this.vendedor_nuevo,
          text4: "Plazo",
          option4: plazo,
          folio: this.tramite.folio,
          descr_activo: description_activo,
          aceptar: async (form, loading) => {
            const resp = await this.perfilService.asignarCelula(form);
            if(resp){
              loading = false
              const data = {
                folio: this.tramite.folio
              }
              const res:any = await this.perfilService.obtenerCelula(data)
              if(res.data){
                console.log("CELULAS ", res)
                this.tramite.celula = res.data.celula
                this.dialog.closeAll();

              }
              // window.location.reload();
        }
          },
          cerrar: () => {
            this.celula_seleccionada = ''
          },
        },
      })
      .afterClosed()
      .subscribe((res) => {
      });
  }
  seleccionarSemanaCompromiso(event: any) {
    const semana = event.target.value;
    this._semana_seleccionada = semana;
    console.log("Semana seleccionada:", semana);
    const agregarSemana = () => {
      const fecha = new Date();
      fecha.setDate(fecha.getDate() + 7 * semana);
      this.fecha_compromiso = fecha;
    };
    agregarSemana();

    if (semana != "") {
      this.dialog
        .open(ModalConfirmComponent, {
          width: "350px",
          height: "350px",

          data: {
            type: "confirm",
            icon_url:
              "https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/eyes.png",
            text1: "¿Estás seguro de las semanas que seleccionarás?",
            text2: this._semana_seleccionada,
            text3: `La fecha compromiso es para: ${this.formatearFechaNueva(
              this.fecha_compromiso
            )}`,
            aceptar: async () => {
              console.log(
                "SEMANA SELECCIONADA Y GUARDADA",
                this._semana_seleccionada
              );
              const form = {
                folio: this.tramite.folio,
                semana_compromiso: this._semana_seleccionada,
              };
              const resp = await this.perfilService.asignarFechaCompromiso(
                form
              );
              if (resp) {
                const data = {
                  folio: this.tramite.folio
                }
                this.dialog.closeAll();
                this._semana_seleccionada = ""
                const res:any = await this.perfilService.obtenerSemanaCompromiso(data)
                if(res.data){
                  this.tramite.fecha_fin_compromiso = res.data.fecha_fin_compromiso
                  this.tramite.fecha_fin_compromiso_created = res.data.created_at
                }
                // window.location.reload();
              }
            },
            cerrar: () => {
              this._semana_seleccionada = ""
              // this.fecha_compromiso = new Date();
            },
          },
        })
        .afterClosed()
        .subscribe((res) => {
          // this.fecha_compromiso = new Date();
        });
    }
  }
  getClassForFechaCompromiso(
    fechaFinCompromiso: string,
    fechaCreacion: string
  ): string {
    const fecha = new Date(fechaFinCompromiso);
    const fechaCreacionDate = new Date(fechaCreacion);

    const today = new Date();
    const diffInMilliseconds = fecha.getTime() - today.getTime();
    const diffInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24));

    const diffInDaysCreacion = Math.ceil(
      (fecha.getTime() - fechaCreacionDate.getTime()) / (1000 * 60 * 60 * 24)
    );
    const mitadTotalFecha = Math.ceil(diffInDaysCreacion / 2);
    if (diffInDays <= 2) {
      return "danger";
    } else if (diffInDays <= mitadTotalFecha) {
      return "warning";
    } else {
      return "success";
    }
  }

  MostrarPopup(documentosRechazados) {
    console.log({documentosRechazados});
    
    if (documentosRechazados && documentosRechazados.length) {
      this.dialog.open(DocumentosContratoRechazadosComponent, {
        width: '560px',
        data: {
          documentos:documentosRechazados
        }
      })
    }
  }
}
