import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-socials-footer',
  templateUrl: './socials-footer.component.html',
  styleUrls: ['./socials-footer.component.scss']
})
export class SocialsFooterComponent implements OnInit {
  date = new Date()
  env = environment.config.ENV
  version = "1.14.12"
  constructor() { }

  ngOnInit(): void {
  }

}
