import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/_models';
import { Perfil } from 'src/app/_models/perfiles';
import { AuthenticationService, SolicitudService } from 'src/app/_services';
import * as ExcelJS from 'exceljs';
import { MatDialog } from '@angular/material/dialog';
import { SelectorFechasComponent } from '../selector-fechas/selector-fechas.component';
import { MatDrawer } from '@angular/material/sidenav';
import { WsService } from 'src/app/_services/ws.service';
import * as CryptoJS from 'crypto-js';


@Component({
  selector: 'app-sidenav-v2',
  templateUrl: './sidenav-v2.component.html',
  styleUrls: ['./sidenav-v2.component.scss']
})
export class SidenavV2Component implements OnInit {
  @Output() openFilters: EventEmitter<void> = new EventEmitter<void>();
  @Output() openNotifications: EventEmitter<void> = new EventEmitter<void>();
  @Output() buscar: EventEmitter<string> = new EventEmitter<string>();
  @Output() onFiltrar: EventEmitter<string> = new EventEmitter<string>();
  @Input() shortMenu = false;
  @Input() placeholderSearch = 'Buscar'
  @Input() commentsUnreadLength: number;
  @Input() buscador = false;
  @Input() filtersAvailable = false
  @Input() filterType = null
  @Input() filtros: any = {}
  @Input() limpiarFiltro = false

  @ViewChild('drawerFilter') drawer!: MatDrawer;

  filterText = ''
  user: any
  nombre_completo = ''
  curLocation = ''
  showText = false
  admin_vendors = false
  tir = false
  crear_perfil = true
  reasignar_tramite = true
  reasignar_usuario = true
  consulta_buro = true
  consulta_buro_perfil = true
  crear_tramite = true
  configuraciones = true
  ver_promociones = true
  reporte_buro = true
  reporte_cartera = true
  ver_referidos = true
  menu= []
  url="https://leaseforu.atlassian.net/servicedesk/customer/portals"
  url_image = '../../../assets/icons/new-icons/cotizacion-vp.svg'
  crear_perfil_con_perfil = true

 
  tipo_dashboard = ''

  puede_crear_perfil = [6,8,10,12,27]
  puede_crear_perfil_con_perfil = [5853]
  puede_reasignar_tramite = [6,12,8,27]
  puede_reasignar_usuario = [6,12,8,25,27]
  puede_consulta_buro = [2,3,4,6,7,12,10,25,27]
  puede_consulta_buro_perfil = [3019, 2408]
  puede_crear_tramite = [6,12,8,2,7,3,9,10,11,25,23,27]
  puede_configuraciones = [4,6,27]
  puede_ver_tir = [6,12,14,15,16,27]
  puede_ver_promociones = [6,12,27]
  puede_admin_vendor = [3,6,10,12,14,15,16,25,23,27]
  puede_reporte_buro = [4,6,10,3,12,27]
  puede_reporte_cartera = [6,12,22,27]
  puede_ver_referidos_user = [7213, 2408,722]
  
  
  penultimateIndex = 0
  ultimateIndex = 0
  

  perfil = null
  id = 0

  fecha_inicial = null
  fecha_final = null

  puede_reporte_solicitudes = false;
  hideNotification = false
  showNotification = false
  messageNotification = ''
  typeNotification = 'info'

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private solicitudService: SolicitudService,
    private dialog: MatDialog,
    private webSocket: WsService,

  ) { }
  ngOnChanges(changes: SimpleChanges){
    this.commentsUnreadLength = this.commentsUnreadLength
    if (this.limpiarFiltro) {
      this.filterText = ''
    }
  }

  ngOnInit(): void {
    this.webSocket.messages$.subscribe( msg => this.WsSubscriber(JSON.parse(msg)))

    this.curLocation = location.href.split('#')[1]

    console.log(this.curLocation);
    this.user = JSON.parse( localStorage.getItem('currentUser') )
    this.nombre_completo = `${this.user.nombre_usuario} ${this.user.apellido_parterno}`.trim()
    this.perfil = Number(this.user.perfil)
    this.id = Number(this.user.id)
    this.tipo_dashboard = Perfil[this.perfil]
    console.log("COMENTS", this.commentsUnreadLength)
    console.log("USER", this.user.id)
    console.log("USER", this.user.perfil)
    console.log("USERCOUNTRY", this.user.country)
  
    this.setPermissions()
    this.menu = [
      {
        icon: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/notes.svg',
        url: '/dashboard/solicitudes',
        title: 'Solicitudes',
        disabled: false
      },
      {
        icon: this.url_image,
        url: '/dashboard/vistas_previas',
        title: 'Vistas previas',
        disabled: this.crear_tramite
      },
      {
        icon: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/buro.svg',
        url: '/dashboard/comite/valuacion/consultaBuro/3',
        title: 'Precalificar',
        disabled: this.consulta_buro
      },

      {
        icon: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/products.svg',
        url: '/productos/admin',
        title: 'Admin. Productos',
        disabled:  this.crear_tramite
      },
      {
        icon: '',
        url: '',
        title: '',
        disabled: this.tir || this.admin_vendors,
        separator: true
      },
      {
        icon: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/detalle_solicitud.svg',
        url: '/detalle_solicitud',
        title: 'Detalle Solicitudes',
        disabled: this.tir
      },
      {
        icon: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/vendors.svg',
        url: '/vendors',
        title: 'Admin Vendors',
        disabled: this.admin_vendors
      },
      {
        icon:'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/referred-menu.png',
        url:'/referidos',
        title: 'Referidos',
        disabled: this.ver_referidos
      },
      {
        icon: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/usuario.svg',
        url: '/dashboard/administrador/crearperfil',
        title: 'Registrar usuario',
        disabled: this.crear_perfil
      },
      {
        icon: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/usuario.svg',
        url: '/dashboard/administrador/crearperfil',
        title: 'Registrar usuario',
        disabled: this.crear_perfil_con_perfil
      },
      {
        icon: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/reasignar.svg',
        url: '/dashboard/administrador/reasignar',
        title: 'Reasignar Usuario',
        disabled: this.reasignar_usuario
      },
      
      {
        icon: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/promociones.svg',
        url: '/promociones',
        title: 'Promociones',
        disabled: this.ver_promociones
      },
      {
        icon: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/reporte_buro.svg',
        url: '/dashboard/administrador/reporte_buro',
        title: 'Reporte Buró',
        disabled: this.reporte_buro
      },
      {
        icon: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/reporte_buro.svg',
        url: '/dashboard/cartera',
        title: 'Reporte Cartera',
        disabled: this.reporte_cartera
      },
      {
        icon: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/catalogos.svg',
        url: '/dashboard/catalogos/cotizador',
        title: 'Catálogos',
        disabled: this.configuraciones,
      },
      {
        icon: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/config.svg',
        url: '/dashboard/administrador/parametros',
        title: 'Configuraciones',
        disabled: this.configuraciones,
      },
      {
        icon: '',
        url: '',
        title: '',
        disabled: false,
        separator: true
      },
      {
        icon: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/change-pass.svg',
        url: '/changePassword',
        title: 'Cambiar contraseña',
        disabled: false,
      },
      {
        icon: 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/logout-new.svg',
        url: '',
        title: 'Cerrar Sesión',
        disabled:false,
      },
      
      // {
      //   icon: '/assets/icons/icon-metricas.png',
      //   url: '/dashboard/metricas/principal',
      //   title: 'Métricas'
      // },
      // {
      //   icon: '/assets/icons/icon-tablas.png',
      //   url: '/dashboard/metricas/tablas',
      //   title: 'Tablas'
      // }
      
  
    ]
    this.penultimateIndex = this.menu.length - 2;
    this.ultimateIndex = this.menu.length - 1;

  }
  To( navigate ) {
    console.log(navigate);
	   let params = {};
	   if (navigate == 'crear_cotizacion') {
		params = {queryParams: {tramite: true}};
	}
    this.router.navigate([navigate], params );
  }
  setPermissions(){
    const NOT_MATCH = -1
    
    this.admin_vendors = this.puede_admin_vendor.indexOf(this.perfil) == NOT_MATCH
    this.crear_perfil = this.puede_crear_perfil.indexOf(this.perfil) == NOT_MATCH
    this.crear_perfil_con_perfil = this.puede_crear_perfil_con_perfil.indexOf(this.user.id) == NOT_MATCH
    this.reasignar_tramite = this.puede_reasignar_tramite.indexOf(this.perfil) == NOT_MATCH
    this.reasignar_usuario = this.puede_reasignar_usuario.indexOf(this.perfil) == NOT_MATCH
    this.consulta_buro = (this.puede_consulta_buro.indexOf(this.user.perfil) === NOT_MATCH) && (this.user.country !== 'MX');
    this.consulta_buro_perfil = this.puede_consulta_buro_perfil.indexOf(this.user.id) == NOT_MATCH 
    this.crear_tramite = this.puede_crear_tramite.indexOf(this.perfil) == NOT_MATCH
    this.configuraciones = this.puede_configuraciones.indexOf(this.perfil) == NOT_MATCH
    this.tir = this.puede_ver_tir.indexOf(this.perfil) == NOT_MATCH
    this.ver_promociones = this.puede_ver_promociones.indexOf(this.perfil) == NOT_MATCH
    this.reporte_buro = this.puede_reporte_buro.indexOf(this.perfil) == NOT_MATCH
    this.reporte_cartera = this.puede_reporte_cartera.indexOf(this.perfil) == NOT_MATCH
    this.puede_reporte_solicitudes = [6,4].includes(Number(this.perfil))
    this.ver_referidos = this.puede_ver_referidos_user.indexOf(this.user.id) == NOT_MATCH
    
    console.log(
      "PERMISOS",
      {admin_vendors: this.admin_vendors,
      crear_perfil: this.crear_perfil,
      crear_perfil_con_perfil: this.crear_perfil_con_perfil,
      reasignar_tramite: this.reasignar_tramite,
      reasignar_usuario: this.reasignar_usuario,
      consulta_buro: this.consulta_buro,
      consulta_buro_perfil: this.consulta_buro_perfil,
      crear_tramite: this.crear_tramite,
      configuraciones: this.configuraciones,
      tir: this.tir,
      ver_promociones: this.ver_promociones,
      ver_referidos: this.ver_referidos,
      reporte_buro: this.reporte_buro,
      user: this.user.id,
      reporte_cartera: this.reporte_cartera
    }
    );
    
  }

  ShowHideCatalogo() {
    const menu = document.getElementById("catalogo-menu");
    menu.style.display = (menu.style.display === "none") ? "block" : "none";
  }
  Filtrar(word: string) {
    this.buscar.emit(word);
  }
  emitOpenFiltersEvent() {
    this.openFilters.emit();
  }
  emitOpenNotificationEvent() {
    this.openNotifications.emit();
  }

  EditarPerfil() {
    localStorage.setItem('vendedor', JSON.stringify({
      apellido_materno: this.user.apellido_materno,
      apellido_parterno: this.user.apellido_parterno,
      email: this.user.email,
      id: this.user.id,
      nombres: this.user.nombre_usuario,
      phone: this.user.phone,
      status: false,
      url_image: this.user.url_image,
    }))
    this.router.navigate(['/vendedor/editar'])
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }


  goIncidenciasTicket(){
    window.open(this.url, '_blank');
  }

  DescargarReporteSolicitudes(){
    console.log("DESCARGAR")
    this.dialog.open(SelectorFechasComponent, {
      width: '50%',
      height: '260px',
      data:{
        fecha_inicial:this.fecha_inicial,
        fecha_final:this.fecha_final 
      }

      
    }).afterClosed().subscribe( async res => {
      if (res != null ){
        console.log(res.fecha_inicial+' 00:00:00');
        const data = {
          "fecha_inicio": res.fecha_inicial+' 00:00:00',
          "fecha_final": res.fecha_final+' 23:59:00',
        }
        try{
          const response: any = await this.solicitudService.ObtenerReporteSolicitudes(data)
            if(response){
              console.log("descargando")
              this.exportToExcel(response?.data, `reporte_solicitud_${res.fecha_inicial}_${res.fecha_final}`)
            }

        }catch(e){
          console.log(e)
        }
        // this.exportToCsv(res, `folio_${this.folio}.csv`)
      }
    })
  }

  exportToExcel(fields: any, filename: string) {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    const header = [
        "Folio",
        "Nombre responsable",
        "Email cliente",
        "Fecha de creación",
        "Id proceso",
        "Nombre Proceso",
        "Id resolución",
        "Nombre Resolución",
        "Fecha Resolución"
    ];

    // Añadir la cabecera
    const headerRow = worksheet.addRow(header);

    // Aplicar estilos a la cabecera
    headerRow.eachCell((cell, colNumber) => {
        cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF000000' }
        };
        cell.alignment = { horizontal: 'center' };
    });

    fields.forEach(row => {
        worksheet.addRow([
            row["folio"],
            row["nombre_responsable"],
            row["email_cliente"],
            row["fecha_creacion"],
            row["id_proceso"],
            row["nombre_proceso"],
            row["id_resolucion"] !== null ? row["id_resolucion"] : "N/A",
            row["nombre_resolucion"] !== null ? row["nombre_resolucion"] : "N/A",
            row["fecha_resolucion"] !== null ? row["fecha_resolucion"] : "N/A",
        ]);
    });

    const fixedWidth = 22; 
    worksheet.columns.forEach(column => {
        column.width = fixedWidth;
    });

    workbook.xlsx.writeBuffer().then(buffer => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename + '.xlsx';
        link.click();
        window.URL.revokeObjectURL(url);
    });
}

  setFilters(filters) {
    console.log({filters});
    this.filtros = {
      ...filters
    }
  }

  AplicarFiltro() {
    if (!Object.keys(this.filtros).length) return 
    this.onFiltrar.emit(this.filtros)
    this.ShowHideFilter()
  }

  ShowHideFilter() {
    if (this.filtersAvailable && this.filterType) {
      this.drawer.toggle()
    }
  }

  async WsSubscriber(msg) {
    const { event, body } = msg
    if (event == 'notification') {
      this.showNotification = true
      this.messageNotification = body.message
      this.typeNotification = body.type || 'success'
      this.OcultarNotificacion(body.displayDuration || 0)
    } else if (event == 'logout') {
      const hash = CryptoJS.MD5(this.authenticationService.User.id.toString()).toString(CryptoJS.enc.Hex);
      if (hash == body.id) {
        this.logout()
        window.location.reload();
      }
    }
  }

  OcultarNotificacion(delay) {
    if (delay > 0) {
      setTimeout(() => {
        this.hideNotification = true
        this.showNotification = false
      }, delay * 1000);
    }

  }

  CloseNotification() {
    console.log('close notification');
    this.messageNotification = ''
    this.showNotification = false
  }
}


