import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService, UserService } from 'src/app/_services';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';

import { customPatterns } from 'src/app/_models/maskPattern';
import { Perfiles, PerfilesBackoffice } from './perfiles';
import { PerfilService } from 'src/app/_services/perfil.service';

@Component({
  selector: 'app-crear-perfil',
  templateUrl: './crear-perfil.component.html',
  styleUrls: ['./crear-perfil.component.scss']
})
export class CrearPerfilComponent implements OnInit {
  ErrorType = {
    Reference: 'Referencia indicada no valida.',
    Email: 'Ocurrió un error al gurdar el usuario'
  };

  DIRECTOR_COMERCIAL = [7,2,3,8,9,10,11,27]
  GERENTE_COMERCIAL = [7,2,3,11]
  COMERCIAL = [7,2,11]
  VENDORS = [7]
  DIRECTOR_OPERACIONES = [14, 15]

  customPattern = customPatterns;
  createForm: FormGroup;
  PERFIL = ['', '', 'Vendor', 'Comercial', 'Comité', 'Administrador', 'Valuación y riesgo'];
  perfil = null;
  user = {name: '', email: '', reference: ''};
  submited = false;
  loading = false;

  reference_error = false;
  email_registered = false;

  esDirectorGeneral = false;
  perfiles = [];
  perfil_seleccionado = 0;

  id_perfil = 123

  perfil_director_comercial = 0
  perfil_director_operaciones = 0
  perfil_gerente_operaciones = 0
  perfil_gerente_comercial = 0
  perfil_comercial = 0
  perfil_vendor = 0

  perfil_padre = 0

  directores_comercial = []
  directores_operaciones = []
  gerentes_operaciones = []
  gerentes_comercial = []
  comerciales = []
  vendors = []

  relaciona = true

  fail_factor = true

  MIN_FACTOR = 0
  MAX_FACTOR = 100

  constructor(private router: Router,
              private authenticationService: AuthenticationService,
              private userService: UserService,
              private perfilService: PerfilService,
              private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    const { id, perfil } = JSON.parse(localStorage.getItem('currentUser'));

    this.createForm = this.formBuilder.group({
      correo: ['', [Validators.required, Validators.pattern(/^([a-zA-Z]|[0-9]+[a-zA-Z]+)([a-zA-Z0-9.a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~])*@[a-zA-Z0-9\-]+\.[a-zA-Z]{2,}/)]],
      nombre: ['', Validators.required],
      a_paterno: [''],
      a_materno: [''],
      factor: ['0', []],
      descuento_ciego: [false]
    });

    this.id_perfil = 3
    this.perfil = 6;
    this.esDirectorGeneral = true; // (this.perfil == 6);
    this.perfiles = perfil == 10 || perfil == 3
      ? PerfilesBackoffice
      : (this.esDirectorGeneral)
        ? Perfiles
        : Perfiles.splice(2);

    console.log(this.perfiles);
    
  }

  get f() { return this.createForm.controls; }
    logout() {
      this.authenticationService.logout();
      this.router.navigate(['/login']);
  }

  CrearUsuario(perfil) {
    this.router.navigateByUrl(`/dashboard/administrador/crearperfil/${perfil}`);
  }

  onSubmit() {
    console.log({
      factor: parseFloat(this.f.factor.value),
        tipo_perfil: this.perfil_seleccionado,
        id_perfil_padre: this.perfil_padre}
    );
    
    
    if ( this.userService.alertService.isOnline() ) {
      this.submited = true;

      if( [2,3,7].indexOf(this.perfil_seleccionado) >= 0 &&
          (this.f.factor.value < this.MIN_FACTOR || this.f.factor.value > this.MAX_FACTOR)) { return this.fail_factor = true }

      if ( this.createForm.invalid || this.perfil_seleccionado == 0 || this.perfil_padre == 0 ) { return }
      this.loading = true;

      this.reference_error = false;
      this.email_registered = false;

      console.log('Creando...: ', {
        ...this.createForm.value,
        factor: parseFloat(this.f.factor.value),
        tipo_perfil: this.perfil_seleccionado,
        id_perfil_padre: this.perfil_padre
      })
      this.userService.CreateUser({
        ...this.createForm.value,
        factor: parseFloat(this.f.factor.value),
        tipo_perfil: this.perfil_seleccionado,
        id_perfil_padre: this.perfil_padre
      }).subscribe( res => {
        console.log('response: ', res);

        const dialogRef = this.dialog.open(MessageBoxComponent, {
          width: '500px',
          data: {
            text: 'Éxito',
            text3: `Se ha generado nuevo perfil`,
            type: 'success',
            icon: 'success'
          }
        });
        this.loading = false;

        dialogRef.afterClosed().subscribe( _ => this.router.navigateByUrl('/dashboard'));

      }, err => {
        console.log('Error: ', err);
        this.loading = false;

        switch ( err.error.message ) {
          case this.ErrorType.Reference:
            this.reference_error = true;
            this.dialog.open(MessageBoxComponent, {
              width: '500px',
              data: {
                text: 'Clave de referencia no válida',
                text3: 'Vuelve a intentarlo.',
                icon: 'error',
                type: 'error'
              }
            });
            break;
          case this.ErrorType.Email:
            this.dialog.open(MessageBoxComponent, {
              width: '500px',
              data: {
                text2: `Correo no válido`,
                text3: 'Vuelve a intentarlo.',
                type: 'error',
                icon: 'error'
              }
            });
            break;
        }
      });
    }

  }

  async Buscar(){
    console.log('perfil_seleccionado: ', this.perfil_seleccionado);
    
    this.relaciona = true 

    this.perfil_gerente_comercial = 0
    this.perfil_director_comercial = 0
    this.perfil_comercial = 0
    this.perfil_vendor = 0
    this.perfil_padre = 0

    this.directores_comercial = []
    this.gerentes_comercial = []
    this.comerciales = []
    this.vendors = []

    console.log(this.perfil_seleccionado);
    if( this.esDirectorGeneral ){
      if( this.DIRECTOR_COMERCIAL.indexOf( this.perfil_seleccionado) >= 0 ){
        const users = await this.perfilService.getUsuarios(Number(this.perfil), this.id_perfil)
        console.log(users);
        
        this.directores_comercial = users['children']['hijos_director_comercial']
        this.directores_comercial.sort( (a,b) => a.nombre_cliente.localeCompare(b.nombre_cliente))
        return
      }

      if( this.DIRECTOR_OPERACIONES.indexOf( this.perfil_seleccionado) >= 0 ){
        const users = await this.perfilService.getUsuarios(Number(this.perfil), this.id_perfil)
        console.log(users);
        
        this.directores_operaciones = users['children']['hijos_director_operaciones']
        this.directores_operaciones.sort( (a,b) => a.nombre_cliente.localeCompare(b.nombre_cliente))
        return
      }
    }else{
      if( this.GERENTE_COMERCIAL.indexOf( this.perfil_seleccionado) >= 0 ){
        const users = await this.perfilService.getUsuarios(Number(this.perfil), this.id_perfil)
        console.log(users);
        
        this.gerentes_comercial = users['children']['hijos_gerente']
        return
      }
    }

    this.relaciona = false
    this.perfil_padre = this.id_perfil
  }

  async GerentesOperaciones(){
    this.perfil_gerente_comercial = 0
    this.perfil_comercial = 0
    this.perfil_vendor = 0
    this.perfil_padre = 0

    this.gerentes_comercial = []
    this.comerciales = []
    this.vendors = []

    if( this.DIRECTOR_OPERACIONES.indexOf(this.perfil_seleccionado) >= 0){
      const users = await this.perfilService.getUsuarios(14, this.perfil_director_operaciones)
      this.gerentes_operaciones = users['children']['hijos_gerentes_operaciones']
      this.gerentes_operaciones.sort( (a,b) => a.nombre_cliente.localeCompare(b.nombre_cliente))
      return
    }
    console.log(this.perfil_director_operaciones);
    
    this.perfil_padre = this.perfil_director_operaciones
  }

  async BuscarGerentesComercial(){
    this.perfil_gerente_comercial = 0
    this.perfil_comercial = 0
    this.perfil_vendor = 0
    this.perfil_padre = 0

    this.gerentes_comercial = []
    this.comerciales = []
    this.vendors = []

    if( this.GERENTE_COMERCIAL.indexOf(this.perfil_seleccionado) >= 0){
      const users = await this.perfilService.getUsuarios(12, this.perfil_director_comercial)
      this.gerentes_comercial = users['children']['hijos_gerente']
      this.gerentes_comercial.sort( (a,b) => a.nombre_cliente.localeCompare(b.nombre_cliente))
      return
    }
    this.perfil_padre = this.perfil_director_comercial
  }

  async BuscarComerciales(){
    this.perfil_comercial = 0
    this.perfil_vendor = 0
    this.perfil_padre = 0

    this.comerciales = []
    this.vendors = []

    if( this.COMERCIAL.indexOf( this.perfil_seleccionado) >= 0 ){
      const users = await this.perfilService.getUsuarios(8, this.perfil_gerente_comercial)
      this.comerciales = users['children']['hijos_comercial']
      this.comerciales.sort( (a,b) => a.nombre_cliente.localeCompare(b.nombre_cliente))
      console.log("Comerciales: ", users); 
      return
    }
    this.perfil_padre = this.perfil_gerente_comercial
  }

  async BuscarVendors( ) {
    this.perfil_vendor = 0
    this.vendors = []

    if( this.VENDORS.indexOf(this.perfil_seleccionado) >= 0 ){
      const users = await this.perfilService.getUsuarios(3, this.perfil_comercial)
      this.vendors = users['children']['hijos_vendor']
      this.vendors.sort( (a,b) => a.nombre_cliente.localeCompare(b.nombre_cliente))
      return
    }

    this.perfil_padre = this.perfil_comercial
  }
}
