import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';
import { emailPattern } from 'src/app/_models/maskPattern';
import { Auto, Product } from 'src/app/_models/product';
import { ProductsService } from 'src/app/_services';
import { CotizadorAutoService } from 'src/app/_services/cotizador-auto.service';
import { VendorsService } from 'src/app/_services/vendors.service';

@Component({
    selector: 'app-nuevo-flujo',
    templateUrl: './nuevo-flujo.component.html',
    styleUrls: ['./nuevo-flujo.component.scss']
})
export class NuevoFlujoComponent implements OnInit {

    COMPANIES = {
        MX: {
            country: 'MX',
            currency: 'MXN',
            residuales: { ap12: 0, ap18: 0, ap24: 0, ap36: 0, ap48: 0, ap60: 0 }
        },
        PE: {
            country: 'PE',
            currency: 'USD',
            residuales: { ap12: 2, ap18: 2, ap24: 2, ap36: 2, ap48: 2, ap60: 2 }
        }
    }
    COMPANY = this.COMPANIES.MX

    MAX_INITIAL_PERCENT = 60
    MIN_FINANCING_AMOUNT = 58000
    MIN_FINANCING_AMOUNT_USD = 5000
    MIN_PERCENT_AVAILABLE = 0

    MAX_DESCUENTO_CIEGO = 20

    PERFILES_VENDOR = [2, 11]

    tipo_cotizacion = 1
    cotizar_auto = false
    edit_commission = false
    commission_for_opening_type = null
    commission_for_opening = null
    tipo_activo = [
        {
            label: "Nuevo",
            value: true,
            activeImageUrl:
                "https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/check-blue-new.svg",
            imageUrl:
                "https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/circle-radio-desactive.svg",
            active: true,
        },
        {
            label: "Remanufacturado",
            value: false,
            activeImageUrl:
                "https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/check-blue-new.svg",
            imageUrl:
                "https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/circle-radio-desactive.svg",
            active: false
        },
    ];

    myControl = new FormControl();
    options: Product[] = []
    ProductosFiltro: Product[] = []
    filteredOptions: Observable<Product[]>;

    folio = 0
    PUEDE_COTIZAR_AUTO = [3,6,12,14,1218, 10]
    USUARIO_COTIZAR_AUTO = [304,3019, 722, 300]
    PERFILES_CAMBIAR_TASA = [6,12,18,25]
    USUARIOS_CAMBIAR_TASA = [10,3019]
    PERFILES_CAMBIAR_TASA_AUTO = [3,10]
    PERFILES_CAMBIAR_COMISION_APERTURA = [6,25,12]
    PUEDE_CAMBIAR_COMISION_APERTURA = false
    USUARIOS_CAMBIAR_TASA_AUTO = [3019, 78, 722, 300]
    PUEDE_CAMBIAR_TASA = false
    PUEDE_CAMBIAR_TASA_AUTO = false
    USUARIO_ESPECIAL_CAMBIAR_TASA_AUTO = [304]

    client_name = 'A QUIEN CORRESPONDA'
    client_first_lastname = ' '
    client_second_lastname = ' '
    client_email: string = ''
    client_phone = ''
    perfil
    id_master
    id_usuario
    IDvendor = 0
    isVendor = false
    tipo_cambio = 1
    tipo_cambio_usd : number = 0.00
    unit_price = 0
    initial_payment = 20
    descuento_ciego = 0
    descuento_ciego_amount = 0
    porcentajeSeguro = 1
    precioSeguroAnual = 0
    descuento_disponible = 0
    descuento = 0
    costo_financiero: any = {}
    msi_seleccionado = ''
    precioSeguroMensualFinanciado = 0
    factor = 17
    initial_payment_amount = 0
    delivery_time = 0
    error_plazo = false
    error_descuento = false
    error_max_percent = false
    error_min_financing_amount = false
    error_min_financing_amount_msi = false
    error_min_financing_amount_usd = false
    error_min_financing_amount_msi_usd = false
    error_max_descuento_ciego = false
    error_email = false
    error_form = false
    error_residual = false
    msi_disponibles = []
    description = ''
    model = ''
    year = ''
    warranty = ''
    tasa_personalizada = 0
    edit_product = false
    isMSI = false;
    remanufactured = false;

    RESIDUALES_ABIERTOS = {
        ap12: 100,
        ap18: 100,
        ap24: 100,
        ap36: 100,
        ap48: 100,
        ap60: 100,
    }

    Plazos = {
        ap12: true,
        ap18: true,
        ap24: true,
        ap36: true,
        ap48: true,
        ap60: false,
        msi6: false,
        msi9: false,
        msi12: false,
        msi15: false,
        msi18: false,
        msi21: false,
        msi24: false
    }

    max_residuales = {
        ap12: 0,
        ap18: 0,
        ap24: 20,
        ap36: 0,
        ap48: 0,
        ap60: 0
    }

    Residuales = {
        ap12: 0,
        ap18: 0,
        ap24: 0,
        ap36: 0,
        ap48: 0,
        ap60: 0
    }

    msi_seleccionados = []

    CotizarProducto: Product
    image_preview: any
    custom_image = null

    is_loading = false
    arrendamiento = []
    only_monthly_payment = false

    current_year = 0
    current_month = ''
    availables_years = Array.from({ length: 10 })

    incluir_tenencia = true
    incluir_placas = true
    incluir_gps = true

    isInputEmpty = false;
    opciones_avanzadas = false;
    table = false;
    selectedOption: any;
    optSelected = 0;

    constructor(
        private dialog: MatDialog,
        private productosService: ProductsService,
        private datePipe: DatePipe,
        private router: Router,
        private route: ActivatedRoute,
        private cotAuto: CotizadorAutoService,
        private vendorService: VendorsService,
    ) { }

    async ngOnInit() {
        const { id, perfil, perfil_master, id_master, cotizar_auto = false } = JSON.parse(localStorage.getItem('currentUser'))
        this.cotizar_auto = this.PUEDE_COTIZAR_AUTO.includes(Number(perfil)) || this.USUARIO_COTIZAR_AUTO.includes(Number(id))

        this.PUEDE_CAMBIAR_TASA = this.PERFILES_CAMBIAR_TASA.includes(Number(perfil)) || this.USUARIOS_CAMBIAR_TASA.includes(Number(id))
        this.PUEDE_CAMBIAR_TASA_AUTO = this.PERFILES_CAMBIAR_TASA_AUTO.includes(Number(perfil)) || this.USUARIOS_CAMBIAR_TASA_AUTO.includes(Number(id))
        this.PUEDE_CAMBIAR_COMISION_APERTURA = this.PERFILES_CAMBIAR_COMISION_APERTURA.includes(Number(perfil)) 

        this.folio = Number(this.route.snapshot.params.folio) || 0
        console.log('Folio: ', this.folio);

        if (this.folio > 0) {
            const { cliente_nombre, cliente_apellido_paterno, cliente_apellido_materno, cliente_telefono, email } = JSON.parse(localStorage.getItem('client_details'))
            this.client_name = cliente_nombre
            this.client_first_lastname = cliente_apellido_paterno
            this.client_second_lastname = cliente_apellido_materno
            this.client_phone = cliente_telefono
            this.client_email = email
        }

        this.IDvendor = this.PERFILES_VENDOR.includes(Number(perfil)) ? Number(id) : this.PERFILES_VENDOR.includes(Number(perfil_master)) ? Number(id_master) : 0
        this.isVendor = this.PERFILES_VENDOR.includes(Number(perfil)) ? true : false

        console.log('Vendor: ', this.IDvendor);


        this.perfil = Number(perfil)
        this.id_usuario = Number(id)
        this.ObtenerProductos()

        const date = this.datePipe.transform(new Date(), 'yyyy-MM-dd')
        this.current_month = this.datePipe.transform(new Date(), 'MMMM')
        this.current_year = Number(date.split('-')[0])
        console.log(date);

        const { tipo_cambio } = await this.productosService.ConsultarTipoCambio(date).toPromise()
        console.log('banxico', tipo_cambio);
        this.tipo_cambio_usd = Number(parseFloat(tipo_cambio).toFixed(2));

        this.CargarProducto()
        // this.ObtenerPlazosDisponibles(this.CotizarProducto.plazos.split(','))

        console.log('CotizarProducto', this.CotizarProducto);
        this.edit_product = this.CotizarProducto.edit;
        this.descuento_disponible = this.CotizarProducto.customer_price - this.CotizarProducto.suggested_customer_price
        this.costo_financiero = this.CotizarProducto.costo_financiero ? JSON.parse(this.CotizarProducto.costo_financiero) : null
        console.log('costo_financiero', this.costo_financiero);

        if (this.costo_financiero && this.costo_financiero.plazos_seleccionados && this.costo_financiero.plazos_seleccionados.length) {
            this.msi_disponibles = this.costo_financiero.plazos_seleccionados
            this.msi_disponibles.sort()
            this.delivery_time = Number(this.costo_financiero.periodo_gracia)
            this.Plazos.ap12 = false
            this.Plazos.ap18 = false
            this.Plazos.ap24 = false
            this.Plazos.ap36 = false
            this.Plazos.ap48 = false
            this.Plazos.ap60 = false
            this.Plazos.msi6 = false
            this.Plazos.msi9 = false
            this.Plazos.msi12 = false
            this.Plazos.msi15 = false
            this.Plazos.msi18 = false
            this.Plazos.msi21 = false
            this.Plazos.msi24 = false
        }

        this.filteredOptions = this.myControl.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value)),
        );
        this.ChangeSeguro()
        this.initial_payment = 20
        this.ChangeInitialPaymentPercent()
        this.image_preview = this.CotizarProducto.url_image

        this.GetVendorConfig()
        console.log("TIPO CAMBIO", this.COMPANY);
    }

    private _filter(value: string): Product[] {
        const filterValue = value.toLowerCase();

        return this.options.filter((option: Product) => option.name.toLowerCase().includes(filterValue));
    }

    BuscarDetalles() {
        console.log('Seleccionado', this.myControl.value);
        this.isMSI = false
        this.arrendamiento = []
        this.msi_disponibles = []
        this.msi_seleccionados = []

        const value = this.myControl.value.toLowerCase()
        this.CotizarProducto = this.options.filter(option => option.name.toLowerCase() == value)[0]

        console.log(this.CotizarProducto);
        this.MostrarDetalesProducto()
    }

    ObtenerProductos() {
        const data = {
            vendor_id: this.IDvendor || 0,
            user_id: this.id_usuario || 0,
            perfil_id: this.perfil
        }

        console.log(data);
        this.productosService.ObtenerProductosVendor(data).subscribe(res => {
            console.log(res);

            this.options = <Product[]>res.data
        })
    }

    MostrarPlazo(plazo) {
        const type = plazo.match(/[a-z]+/gi)[0]
        const months = plazo.match(/[0-9]+/gi)[0]

        return `${months}${type === 'msi' ? ' MSI' : ''}`

    }

    // ObtenerPlazosDisponibles(plazos) {
    // for (const plazo of plazos) {
    // this.plazsos_disponibles[plazo] = true
    // }
    // console.log(this.plazsos_disponibles);
    // for (const plazo in this.plazsos_disponibles) {
    // console.log(plazo);

    // }
    // }

    ObtenerPlazos() {
        const plazos = []
        if (this.msi_disponibles.length && this.msi_seleccionados.length > 0) return ''
        for (const plazo of Object.keys(this.Plazos)) {
            if (this.Plazos[plazo]) {
                plazos.push(plazo)
            }
        }

        if (!plazos.length) {
            this.error_plazo = true
            this.is_loading = false
            throw "Necesitas minimo un plazo"
        }
        return plazos.join(',')
    }

    ActivarDesactivarPlazo(plazo) {
        // if (!this.isVendor) return


        this.Plazos.msi6 = false
        this.Plazos.msi9 = false
        this.Plazos.msi12 = false
        this.Plazos.msi15 = false
        this.Plazos.msi18 = false
        this.Plazos.msi21 = false
        this.Plazos.msi24 = false

        if (plazo === '') {
            this.Plazos.msi6 = false
            this.Plazos.msi9 = false
            this.Plazos.msi12 = false
            this.Plazos.msi15 = false
            this.Plazos.msi18 = false
            this.Plazos.msi21 = false
            this.Plazos.msi24 = false
            return
        }

        console.log('ActivarDesactivarPlazo: ', plazo, this.msi_seleccionado);

        this.error_plazo = false
        this.Plazos[plazo] = !this.Plazos[plazo]

        const type = plazo.match(/[a-z]*/gi)[0] || null
        const value = plazo.match(/[0-9]+/gi)[0] || null

        if (type === 'ap') {
            this.Cotizar(false, false, true, false)
            this.Plazos[`msi${value}`] = this.Plazos[plazo] ? false : this.Plazos[`msi${value}`]
            if (this.msi_seleccionado.split('-')[0] === `msi${value}`) {
                this.msi_seleccionado = ''
            }
        } else {
            this.Plazos[`ap${value}`] = this.Plazos[plazo] ? false : this.Plazos[`ap${value}`]
        }

        if (this.msi_seleccionado.split('-')[0] !== '') {
            this.Plazos[this.msi_seleccionado.split('-')[0]] = true
        }
    }

    /*
    {
    "product_id": 1,
    "monthly_rate": 0.025, **
    "amount": 1000000.0,
    "blind_discount": 0,
    "vendor_payment": 0,
    "delivery_time": 0,
    "initial_payment": 0.2,
    "residual": 0.1,
    "periods": "ap12,ap36,msi12"
   }
   */

    Cotizar(preview, send = false, only_monthly_payment = false, nueva_solicitud) {
        if (this.unit_price == 0 || (only_monthly_payment == false && !this.formValidator())) return

        this.is_loading = true
        console.log({
            factor: Number(this.tasa_personalizada) > 0 ? Number(this.tasa_personalizada) * 6 : null,
            folio: this.folio,
            user_id: this.id_usuario,
            vendor_id: this.IDvendor,
            client_name: this.client_name,
            client_first_lastname: this.client_first_lastname,
            client_second_lastname: this.client_second_lastname,
            client_email: this.client_email.toLowerCase(),
            client_phone: this.client_phone,
            product_id: this.CotizarProducto.id || 0,
            amount: Number(this.unit_price),
            blind_discount: 0,
            vendor_payment: 0,
            delivery_time: Number(this.delivery_time),
            initial_payment: this.initial_payment / 100,
            msi_initial_payment: Number(this.msi_seleccionado.split('-')[1]) / 100 || 0,
            precioSeguroMensualFinanciado: this.precioSeguroMensualFinanciado || 0,
            periods: this.ObtenerPlazos(),
            discount_amount: Number(this.descuento) * this.tipo_cambio,
            residuales: this.Residuales,
            send,
            description: this.description,
            model: this.model,
            year: this.year,
            warranty: this.warranty,
            msi_seleccionados: this.msi_seleccionados,
            custom_image: this.custom_image ? this.custom_image : null,
            only_monthly_payment,
            country: this.COMPANY.country,
            currency: this.COMPANY.currency,
            monto_descuento_ciego: Number(this.descuento_ciego_amount) || 0,
            descuento_ciego: this.descuento_ciego,
            remanufactured: this.remanufactured,

        });

        this.productosService.CotizarProducto({
            factor: Number(this.tasa_personalizada) > 0 ? Number(this.tasa_personalizada) * 6 : null,
            folio: this.folio,
            preview,
            user_id: this.id_usuario,
            vendor_id: this.IDvendor,
            client_name: `${this.client_name}`,
            client_first_lastname: this.client_first_lastname,
            client_second_lastname: this.client_second_lastname,
            client_email: this.client_email.toLowerCase(),
            client_phone: this.client_phone,
            product_id: this.CotizarProducto.id || 0,
            amount: Number(this.unit_price),
            blind_discount: 0,
            vendor_payment: 0,
            delivery_time: Number(this.delivery_time),
            initial_payment: this.initial_payment / 100,
            msi_initial_payment: Number(this.msi_seleccionado.split('-')[1]) / 100 || 0,
            periods: this.ObtenerPlazos(),
            precioSeguroMensualFinanciado: this.precioSeguroMensualFinanciado || 0,
            sin_producto: this.tipo_cotizacion == 2 || this.tipo_cotizacion == 3,
            auto: this.tipo_cotizacion == 3,
            discount_amount: Number(this.descuento) * this.tipo_cambio,
            residuales: this.Residuales,
            send,
            description: this.description,
            model: this.model,
            year: this.year,
            warranty: this.warranty,
            msi_seleccionados: this.msi_seleccionados,
            custom_image: this.custom_image ? this.custom_image : null,
            only_monthly_payment,
            monto_descuento_ciego: Number(this.descuento_ciego_amount) || 0,
            descuento_ciego: this.descuento_ciego,
            country: this.COMPANY.country,
            currency: this.COMPANY.currency,
            remanufactured: this.remanufactured,
            ...this.CotizarProducto.caracteristicas || {},
            edit_commission: this.edit_commission,
            commission_for_opening_type: this.commission_for_opening_type,
            commission_for_opening: this.commission_for_opening,
        }).subscribe(res => {
            console.log(res);
            console.log('url: ', res.url_cotizacion);


            if (!only_monthly_payment) {
                window.open(res.url_cotizacion, "downlaod");
                this.ShowDialog(`${nueva_solicitud ? 'confirm' : 'success'}`, `Se ha generado la ${preview ? 'vista previa' : 'cotización'} con éxito con el folio ${res.folio}`, `${nueva_solicitud ? '¿Deseas continuar con el llenado de solicitud?' : ''}`, !preview, nueva_solicitud, res.folio, this.client_email.toLowerCase())
                this.is_loading = false
            } else {
                this.arrendamiento = [...res.arrendamiento_puro, ...res.meses_sin_intereses];
                console.log("TASA,TIR", this.arrendamiento)
                this.is_loading = false
            }
        }, err => {
            console.log(err);
            this.is_loading = false
            this.ShowDialog('error', `Ocurrió un error`, err.error.message || err.name)
        })

    }

    ChangeUnitPrice() {
        this.unit_price = (this.CotizarProducto.customer_price * this.tipo_cambio) - (this.descuento * this.tipo_cambio)
        this.ChangeInitialPayment()
    }

    ChangeInitialPayment() {
        this.initial_payment = (this.initial_payment_amount * 100) / this.unit_price
        // this.initial_payment_amount = (this.initial_payment /100) *this.unit_price
        this.ChangeSeguro()
    }

    ChangeInitialPaymentPercent() {
        this.initial_payment_amount = (this.initial_payment / 100) * this.unit_price
        this.ChangeSeguro()
    }

    ChangeSeguro() {
        this.precioSeguroAnual = (Math.round((this.unit_price) * (Number(this.porcentajeSeguro) / 100)));
        this.precioSeguroMensualFinanciado = (Math.round((Number(this.precioSeguroAnual) * (1 + Number(this.factor) / 100)) / 12));

        console.log('Percent:', this.initial_payment);
        console.log('Financing amount: ', this.unit_price - this.initial_payment_amount);
        this.error_max_percent = !this.isMSI && this.initial_payment > this.MAX_INITIAL_PERCENT
        console.log("MAXIMO PORCENTAJE", this.error_max_percent)
        this.error_min_financing_amount = !this.msi_disponibles.length && (Number(this.unit_price - this.initial_payment_amount) < Number(this.MIN_FINANCING_AMOUNT))
        this.error_min_financing_amount_usd = !this.msi_disponibles.length && (Number(this.unit_price - this.initial_payment_amount) < Number(this.MIN_FINANCING_AMOUNT_USD))
        this.error_min_financing_amount_msi_usd = this.msi_disponibles.length && (Number(this.unit_price - this.initial_payment_amount) < Number(this.MIN_FINANCING_AMOUNT_USD))
        this.error_max_descuento_ciego = this.descuento_ciego > this.MAX_DESCUENTO_CIEGO

        if ((this.tipo_cotizacion == 1 && this.CotizarProducto.id > 0) || this.tipo_cotizacion == 2) {
            this.Cotizar(false, false, true, false)
        }

        if (this.tipo_cotizacion == 3) {
            this.CotizacionAuto()
        }
    }

    ShowDialog(type, text, text2 = '', home_redirect = false, nueva_solicitud = false, folio = '', correo = '') {
        const dialog = this.dialog.open(MessageBoxComponent, {
            width: "500px",
            data: {
                text,
                text2,
                type,
                options: nueva_solicitud ? 'options' : '',
                opt1: 'Si',
                opt2: 'No',
                icon: type,
                confirmopt: (val: number) => this.optSelected = val,
            },
        })

        if (home_redirect && !nueva_solicitud) {
            dialog.afterClosed().subscribe(() => {
                this.router.navigate(['dashboard', 'principal'])
            })
        }
        if (home_redirect && nueva_solicitud) {
            dialog.afterClosed().subscribe(() => {
                if (this.optSelected === 1) {
                    this.router.navigate(['nueva_solicitud'], { queryParams: { folio, correo, flujo_cotizador: true } })
                } else if (this.optSelected === 2) {
                    this.router.navigate(['dashboard', 'principal'])
                }
                else {
                    this.router.navigate(['dashboard', 'principal'])
                }
            })
        }
    }

    SetDescuento() {
        this.error_descuento = (this.descuento > this.descuento_disponible)
        this.ChangeUnitPrice()
    }

    Cancelar() {
        this.router.navigate(['dashboard', 'principal'])
    }

    CargarProducto() {
        const cotizarProducto = localStorage.getItem('cotizar_producto') || null
        this.CotizarProducto = JSON.parse(cotizarProducto) || new Product
        this.tipo_cambio = this.CotizarProducto.currency === 'MXN' || this.COMPANY.currency == 'USD' ? 1 : this.tipo_cambio_usd;
        this.description = this.CotizarProducto.description
        this.max_residuales = this.id_usuario == 3 ? { ...this.RESIDUALES_ABIERTOS } : JSON.parse(this.CotizarProducto.residuales)
        // this.Residuales = JSON.parse(this.CotizarProducto.residuales)
        this.Residuales = this.COMPANY.residuales
        this.model = this.CotizarProducto.model || ''
        this.year = this.CotizarProducto.year || ''
        this.warranty = this.CotizarProducto.warranty || ''

        this.MostrarDetalesProducto()
        // this.ChangeUnitPrice()
    }

    LimpiarProducto() {
        localStorage.removeItem('cotizar_producto')
        this.max_residuales = this.id_usuario == 3 ? { ...this.RESIDUALES_ABIERTOS } : JSON.parse(this.CotizarProducto.residuales)
        this.CotizarProducto = new Product
        this.custom_image = null
    }

    AgregaQuitarMSI(value) {
        let encontrado = false
        let index_encontrado = -1

        for (let i = 0; i < this.msi_seleccionados.length; i++) {
            const msi = this.msi_seleccionados[i];
            encontrado = msi.plazo == value.plazo && msi.porcentaje == value.porcentaje
            if (encontrado) {
                index_encontrado = i
                break
            }
        }

        if (encontrado) {
            this.msi_seleccionados.splice(index_encontrado, 1)
        } else { this.msi_seleccionados.push(value) }

        if (this.msi_seleccionados.length) {
            this.Cotizar(false, false, true, false)
        }
    }

    CheckMSISeleccionado(value) {
        const encontrado = this.msi_seleccionados.filter(msi => msi.plazo == value.plazo && msi.porcentaje == value.porcentaje)
        return encontrado.length == 1
    }

    CotizarSinProducto() {
        this.CotizarProducto = new Product()
        this._ConfigCotizador()
    }

    CotizarAuto() {
        this.CotizarProducto = new Auto()
        this.CotizarProducto.caracteristicas.year = this.current_year
        this.CotizarProducto.caracteristicas.month = this.current_month
        this.descuento = 0
        this.descuento_disponible = 0

        this._ConfigCotizador()
        this.CotizarProducto.customer_price = 0;
        this.initial_payment = 0
        this.initial_payment_amount = 0
        this.max_residuales = this.id_usuario == 3 ? { ...this.RESIDUALES_ABIERTOS } : JSON.parse(this.CotizarProducto.residuales)
    }

    CotizacionAuto() {
        const { gps_inicial, gps_mensualidad } = this.cotAuto.CalcularGPS(this.CotizarProducto.caracteristicas.estado)

        this.CotizarProducto.caracteristicas.gps_inicial = this.incluir_gps ? gps_inicial : 0
        this.CotizarProducto.caracteristicas.gps_mensualidad = this.incluir_gps ? gps_mensualidad : 0

        this.CotizarProducto.caracteristicas.tenencia = !this.incluir_tenencia ? 0
            : this.cotAuto.CalcularTenencia({
                tipo_persona: 2,
                monto: this.CotizarProducto.customer_price,
                mes: this.current_month,
                estado: this.CotizarProducto.caracteristicas.estado,
                nuevo: this.CotizarProducto.caracteristicas.nuevo
            })

        this.CotizarProducto.caracteristicas.placas = !this.incluir_placas ? 0
            : this.cotAuto.CalcularPlacas({
                estado: this.CotizarProducto.caracteristicas.estado,
            })
            if (!this.edit_commission) {
                this.resetCommissionDefaults();
              }

        this.Cotizar(false, false, true, false)
    }

    _ConfigCotizador() {
        localStorage.removeItem('cotizar_producto')
        this.max_residuales = this.id_usuario == 3 ? { ...this.RESIDUALES_ABIERTOS } : JSON.parse(this.CotizarProducto.residuales)
        this.msi_seleccionado = ''
        this.msi_seleccionados = []

        this.Plazos.ap12 = true
        this.Plazos.ap18 = true
        this.Plazos.ap24 = true
        this.Plazos.ap36 = true
        this.Plazos.ap48 = true
        this.Plazos.ap60 = false

        this.ActivarDesactivarPlazo('')
        console.log(this.CotizarProducto);

        this.image_preview = this.CotizarProducto.url_image
        this.CotizarProducto.customer_price = 0;
        this.initial_payment = 20
        this.initial_payment_amount = 20000
        this.descuento = 0
        this.msi_disponibles = []
        this.costo_financiero = null
        this.tipo_cambio = 1
        this.CotizarProducto.currency = 'USD'
        this.ChangeUnitPrice()
        this.Cotizar(false, false, true, false)
    }

    formValidator() {
        if (this.client_email.match(emailPattern) == null) {
            this.error_email = true
        }
        if (!this.client_name ||
            !this.client_first_lastname ||
            !this.client_second_lastname ||
            !this.client_email ||
            this.client_email.match(emailPattern) == null ||
            !this.client_phone || this.client_phone.length < 9) {
            this.error_form = true
            return false
        }

        return true
    }

    ValidarResiduales() {
        this.error_residual = false
        if (
            (isNaN(this.Residuales.ap12) || this.Residuales.ap12 > this.max_residuales.ap12) ||
            (isNaN(this.Residuales.ap18) || this.Residuales.ap18 > this.max_residuales.ap18) ||
            (isNaN(this.Residuales.ap24) || this.Residuales.ap24 > this.max_residuales.ap24) ||
            (isNaN(this.Residuales.ap36) || this.Residuales.ap36 > this.max_residuales.ap36) ||
            (isNaN(this.Residuales.ap48) || this.Residuales.ap48 > this.max_residuales.ap48) ||
            (isNaN(this.Residuales.ap60) || this.Residuales.ap60 > this.max_residuales.ap60)
        ) {
            this.error_residual = true
        }

        if (this.tipo_cotizacion == 3) {
            if (
                (this.Plazos.ap12 && !(this.Residuales.ap12 >= 0)) ||
                (this.Plazos.ap18 && !(this.Residuales.ap18 >= 0)) ||
                (this.Plazos.ap24 && !(this.Residuales.ap24 >= 0)) ||
                (this.Plazos.ap36 && !(this.Residuales.ap36 >= 0)) ||
                (this.Plazos.ap48 && !(this.Residuales.ap48 >= 0)) ||
                (this.Plazos.ap60 && !(this.Residuales.ap60 >= 0))
            ) {
                this.error_residual = true
            }
        }
        this.ChangeSeguro()
        return this.error_residual
    }

    ValidarMinFinancingMSI(initial_payment) {
        const initial_payment_amount = this.unit_price * (initial_payment / 100)

        if (Number(this.unit_price - initial_payment_amount) > Number(this.MIN_FINANCING_AMOUNT)) {
            return true
        }
        if (Number(this.unit_price - initial_payment_amount) > Number(this.MIN_FINANCING_AMOUNT_USD)) {
            return true
        }

        this.error_min_financing_amount_msi = true
        this.error_min_financing_amount_msi_usd = true

        return false
    }

    HayError() {
        return this.error_descuento || this.error_plazo || this.error_max_percent || this.error_residual
            || (this.initial_payment > 0 && this.initial_payment < this.MIN_PERCENT_AVAILABLE) || (this.msi_disponibles.length && this.msi_seleccionados.length == 0)
            || this.error_max_descuento_ciego || (this.COMPANY.country == 'PE' ? this.error_min_financing_amount_usd : this.error_min_financing_amount)
    }
    ImagePreview() {
        const producto: any = document.querySelector("input[name='product_image']")
        const file = producto.files[0]

        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            this.custom_image = reader.result
            console.log()
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    async MostrarDetalesProducto() {
        await this.GetVendorConfig()
        this.image_preview = this.CotizarProducto.url_image

        this.edit_product = this.CotizarProducto.edit;
        this.max_residuales = this.id_usuario == 3  ? { ...this.RESIDUALES_ABIERTOS } : JSON.parse(this.CotizarProducto.residuales)
        // this.Residuales = JSON.parse(this.CotizarProducto.residuales)
        this.Residuales = this.COMPANY.residuales
        this.description = this.CotizarProducto.description || ''
        this.model = this.CotizarProducto.model || ''
        this.year = this.CotizarProducto.year || ''
        this.warranty = this.CotizarProducto.warranty || ''
        // this.ObtenerPlazosDisponibles(this.CotizarProducto.plazos.split(','))
        this.tipo_cambio = this.CotizarProducto.currency === 'MXN' || this.COMPANY.currency == 'USD' ? 1 : this.tipo_cambio_usd;
        this.myControl.setValue('')
        this.descuento_disponible = this.CotizarProducto.customer_price - this.CotizarProducto.suggested_customer_price

        console.log('CotizarProducto', this.CotizarProducto);
        this.costo_financiero = this.CotizarProducto.costo_financiero ? JSON.parse(this.CotizarProducto.costo_financiero) : null
        console.log('costo_financiero', this.costo_financiero);

        this.isMSI = this.costo_financiero && this.costo_financiero.plazos_seleccionados && this.costo_financiero.plazos_seleccionados.length > 0

        console.log(`is msi: ${this.isMSI}`);

        if (this.isMSI) {
            this.msi_disponibles = this.costo_financiero.plazos_seleccionados
            this.msi_disponibles.sort()
            this.delivery_time = Number(this.costo_financiero.periodo_gracia)
            this.Plazos.ap12 = false
            this.Plazos.ap18 = false
            this.Plazos.ap24 = false
            this.Plazos.ap36 = false
            this.Plazos.ap48 = false
            this.Plazos.ap60 = false
            this.Plazos.msi6 = false
            this.Plazos.msi9 = false
            this.Plazos.msi12 = false
            this.Plazos.msi15 = false
            this.Plazos.msi18 = false
            this.Plazos.msi21 = false
            this.Plazos.msi24 = false

            this.msi_seleccionados = [...this.msi_disponibles]
        } else {
            this.Plazos.ap12 = true
            this.Plazos.ap18 = true
            this.Plazos.ap24 = true
            this.Plazos.ap36 = true
            this.Plazos.ap48 = true
            this.Plazos.ap60 = false
        }
        this.initial_payment = this.CotizarProducto.customer_price == 1 ? 0 : 20
        this.ChangeUnitPrice()

        this.ChangeSeguro()
        this.ChangeInitialPaymentPercent()
    }

    async GetVendorConfig() {
        const id_vendor = [2, 7].includes(this.perfil)
            ? this.IDvendor
            : this.tipo_cotizacion == 1 && this.CotizarProducto.id > 0
                ? this.CotizarProducto.vendor_id
                : 0

        const info_vendor = await this.vendorService.ObtenerVendorPorId(id_vendor).toPromise()
        console.log("INFO VENDOR", { info_vendor });
        this.COMPANY = this.COMPANIES[info_vendor.data.country]
        if (this.COMPANY.country == 'PE') {
            this.tipo_cambio = 1
        }
    }

    ChangeDiscount() {
        this.descuento_ciego = Number((this.descuento_ciego_amount * 100 / this.unit_price).toFixed(2))
        this.ChangeSeguro()
    }

    ChangeDiscountPercent() {
        this.descuento_ciego_amount = Number((this.unit_price * (this.descuento_ciego / 100)).toFixed(2))
        this.ChangeSeguro()
    }

    clearImg() {
        this.custom_image = null
    }

    Filtrar(filtro: string) {
        console.log(filtro)
        this.ProductosFiltro = this.options.filter(Producto =>
            Producto.name.toLowerCase().includes(filtro.toLowerCase()) ||
            Producto.model.toLowerCase().includes(filtro.toLowerCase()) ||
            Producto.year.toLowerCase().includes(filtro.toLowerCase()) ||
            Producto.warranty.toLowerCase().includes(filtro.toLowerCase()) ||
            Producto.customer_price.toString().includes(filtro.toLowerCase()) ||
            Producto.suggested_customer_price.toString().includes(filtro.toLowerCase()) ||
            Producto.nombres.toString().includes(filtro.toLowerCase())
        )
    }
    updateSelectedOption(value: any) {
        this.tipo_activo.forEach(option => {
            option.active = option.value === value;
        });

        console.log('Opción seleccionada:', value);
    }

    getOptionImageUrl(option: any): string {
        return option.active ? option.activeImageUrl : option.imageUrl;
    }
    shareOnWhatsApp() {
        this.is_loading = true
        this.productosService.CotizarProducto({
            factor: Number(this.tasa_personalizada) > 0 ? Number(this.tasa_personalizada) * 6 : null,
            folio: this.folio,
            preview: false,
            user_id: this.id_usuario,
            vendor_id: this.IDvendor,
            client_name: `${this.client_name}`,
            client_first_lastname: this.client_first_lastname,
            client_second_lastname: this.client_second_lastname,
            client_email: this.client_email.toLowerCase(),
            client_phone: this.client_phone,
            product_id: this.CotizarProducto.id || 0,
            amount: Number(this.unit_price),
            blind_discount: 0,
            vendor_payment: 0,
            delivery_time: Number(this.delivery_time),
            initial_payment: this.initial_payment / 100,
            msi_initial_payment: Number(this.msi_seleccionado.split('-')[1]) / 100 || 0,
            periods: this.ObtenerPlazos(),
            precioSeguroMensualFinanciado: this.precioSeguroMensualFinanciado || 0,
            sin_producto: this.tipo_cotizacion == 2 || this.tipo_cotizacion == 3,
            auto: this.tipo_cotizacion == 3,
            discount_amount: Number(this.descuento) * this.tipo_cambio,
            residuales: this.Residuales,
            send: false,
            description: this.description,
            model: this.model,
            year: this.year,
            warranty: this.warranty,
            msi_seleccionados: this.msi_seleccionados,
            custom_image: this.custom_image ? this.custom_image : null,
            only_monthly_payment: false,
            monto_descuento_ciego: Number(this.descuento_ciego_amount) || 0,
            descuento_ciego: this.descuento_ciego,
            country: this.COMPANY.country,
            currency: this.COMPANY.currency,
            remanufactured: this.remanufactured,
            ...this.CotizarProducto.caracteristicas || {},
        }).subscribe(res => {
            console.log(res);
            console.log('url: ', res.url_cotizacion);


            if (!this.only_monthly_payment) {
                // window.open(res.url_cotizacion, "downlaod");
                this.is_loading = false
                const whatsappBaseUrl = 'https://api.whatsapp.com/send';
                const message = encodeURIComponent(`Aquí tienes el PDF que solicitas: ${res.url_cotizacion}`);
                const whatsappUrl = `${whatsappBaseUrl}?phone=${this.client_phone}&text=${message}`;

                window.open(whatsappUrl, '_blank');
            } else {
                this.arrendamiento = [...res.arrendamiento_puro, ...res.meses_sin_intereses];
                console.log("TASA,TIR", this.arrendamiento)
                this.is_loading = false
            }
        }, err => {
            console.log(err);
            this.is_loading = false
            this.ShowDialog('error', `Ocurrió un error`, err.error.message || err.name)
        })


        // const whatsappBaseUrl = 'https://api.whatsapp.com/send?text=';
        // const url = "https://static.api.leaseforu.com/cotizaciones/e969d4500f051f9b8e4712ccd3bbc6f8c24ef815b6b0cba4291c47211d444042/folio_873153/Cotizacion_v1_1716869894372.pdf"
        // const message = encodeURIComponent(`Aquí tienes el PDF que solicitas: ${url}`);
        // const whatsappUrl = `${whatsappBaseUrl}${message}`;

        // window.open(whatsappUrl, '_blank');
    }
    resetCommissionDefaults() {
        this.commission_for_opening_type = null; 
        this.commission_for_opening = null; 
      }


}
