<app-sidenav-v2 [shortMenu]="true">
    <div class="container">
        <div class="top">
            <div class="title"> 
                <div class="acces-vendores-editar">
                    <app-go-bak></app-go-bak> 
                    <span style="margin-left: 10px;"> Editar Vendor </span>
                </div>
                <div class="acces-vendores-configuraciones">
                    <button [ngClass]="{'primary': vista == 1, 'secondary': vista != 1}"
                    (click)="vista = 1">Vendor</button>
                     <button *ngIf="puede_ver_vendedores.includes(perfil)"
                    [ngClass]="{'primary': vista == 2, 'secondary': vista != 2}"
                    (click)="vista = 2">Vendedores</button>
                     <button *ngIf="puede_ver_vendedores.includes(perfil)"
                    [ngClass]="{'primary': vista == 3, 'secondary': vista != 3}"
                    (click)="vista = 3">Documentos</button>
                     <button *ngIf="puede_ver_vendedores.includes(perfil)"
                    [ngClass]="{'primary': vista == 4, 'secondary': vista != 4}"
                    (click)="vista = 4">Campos Solicitud</button>
                </div>
            </div>               
        </div>
        <div class="box">
            <app-input-solicitud-vendor *ngIf="vista == 4" [id_vendor]="Vendor.id" style="height: 100%;" [inputs]="listaInputs"></app-input-solicitud-vendor>
            <app-documentos-vendor *ngIf="vista == 3" [id_vendor]="Vendor.id" style="height: 100%;" [documentos]="listaDocumentos" ></app-documentos-vendor>
            <app-vendedores-list *ngIf="vista == 2" [id_vendor]="Vendor.user_id"></app-vendedores-list>
            <div class="vendor_form" style="margin-right: 20px;">
                <div *ngIf="vista == 1">
                    <!-- <form> -->
                    <input [(ngModel)]="Vendor.id" type="hidden" name="id" />
                    <div id="vendor_header">
                        <div class="vendor_imagen">
                            <div>
                                <div class="container-img">
                                    <div class="container-img-photo">
                                        <img id="vendor_no_image" [src]="image_preview" alt="Agregar imagen" *ngIf="image_preview == 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/no-photo.png'">
                                        <img id="vendor_image" [src]="image_preview" alt="Agregar imagen"  *ngIf="image_preview != 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/no-photo.png'">
                                    </div>   
                                </div>
                            </div>
                            <div class="text_imagen_vendor">Recuerda que la imagen debe estar en formato .jpg de 800 * 800px y con un peso máximo de 10 MB.
                            </div>
                            <div class="container-input">
                                <input type="file" 
                                accept=".jpg, .jpeg" 
                                name="vendor_image" 
                                id="vendor_image" 
                                class="inputfile inputfile-1" 
                                data-multiple-caption="{count} archivos seleccionados" 
                                (change)="ImagePreview()" />
                                <label for="vendor_image">
                                    <img src="../../../../assets/icons/upload-degradated.png" 
                                    style="margin-right: 3px;">
                                <span class="iborrainputfile">Cargar la imagen</span>
                                </label>
                            </div>
                        </div>
                        <!--Campos-->
                        <!--id="vendor_name"-->
                        <div id="vendor_name">
                            <div class="custom-form-field">
                                <mat-form-field appearance="outline">
                                    <mat-label>Nombre</mat-label>
                                    <input  matInput type="text" name="name" id="name" placeholder=" "
                                    [disabled]="!(puede_cambiar_nombre.includes(perfil) || usuario_puede_cambiar_configuracion.includes(id))" required
                                    [(ngModel)]="Vendor.fullname" style="width: 90%;">
                                </mat-form-field>
                            </div>
                            <div class="custom-form-field">
                                <mat-form-field appearance="outline" style="width: 50%;">
                                    <mat-label for="name">ID</mat-label>
                                <input matInput [disabled]="!(puede_cambiar_nombre.includes(perfil) || usuario_puede_cambiar_configuracion.includes(id))"
                                    [(ngModel)]="Vendor.id_vendor" type="text" name="name" id="name"/>
                                </mat-form-field>
                            </div>
                            <!-- Seccion de Configuracion -->
                            <span class="subtitle">Configuración</span>
                            <div class="distribution-elment">
                                <!-- <div class="subtitle">Configuración</div> -->
                                <div class="elemnt-row">
                                    <div class="custom-form-field" style="width: 90%;" >
                                        <mat-form-field appearance="outline" style="width: 100%;">
                                            <mat-label>País</mat-label>
                                        <mat-select [disabled]="!(puede_cambiar_configuracion.includes(perfil) || usuario_puede_cambiar_configuracion.includes(id))"
                                                        [(ngModel)]="Vendor.country" name="factor">
                                                        <mat-option  [value]="pais.code" *ngFor="let pais of PAISES">{{pais.code}}:
                                                            {{pais.name}}</mat-option>
                                        </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="custom-form-field" style="width: 90%;">
                                        <mat-form-field appearance="outline" style="width: 80%; margin-left: 10px;">
                                        <mat-label>Factor</mat-label>
                                        <input matInput [disabled]="!(puede_cambiar_configuracion.includes(perfil) || usuario_puede_cambiar_configuracion.includes(id))"
                                                    type="text" [(ngModel)]="Vendor.factor"
                                                    name="factor">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="elemnt-row">
                                    <div class="custom-form-field space" style="width: 90%;">
                                        <mat-form-field appearance="outline" style="width: 100%;">
                                            <mat-label>Póliza de mantenimiento c/IVA</mat-label>
                                        <input matInput
                                        [disabled]="!(puede_cambiar_configuracion.includes(perfil) || usuario_puede_cambiar_configuracion.includes(id))" matInput
                                        mask="separator.2" thousandSeparator="," prefix="$ " type="text"
                                        [(ngModel)]="Vendor.annual_insurance_amount" 
                                        name="annual_insurance_amount" >
                                        </mat-form-field>
                                    </div>
                                    <div class="custom-form-field space" style="width: 90%;">
                                        <mat-form-field appearance="outline" style="width: 80%; margin-left: 10px;">
                                            <mat-label>Pago a vendedor</mat-label>
                                        <mat-select [disabled]="!(puede_cambiar_configuracion.includes(perfil) || usuario_puede_cambiar_configuracion.includes(id))"
                                                    name="vendor_payment" id="vendor_payment"
                                                    [(ngModel)]="Vendor.vendor_payment" >
                                                    <mat-option value="0">Inmediato</mat-option>
                                                    <mat-option value="1">1 mes</mat-option>
                                                    <mat-option value="2">2 meses</mat-option>
                                                    <mat-option value="3">3 meses</mat-option>
                                                </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="elemnt-row">
                                    <p> 
                                    <mat-slide-toggle  [disabled]="!(puede_cambiar_configuracion.includes(perfil) || usuario_puede_cambiar_configuracion.includes(id))" 
                                                        [checked]="Vendor.cotizar_auto"  
                                                        (change)="ChangeCotizarAuto()" > Permitir modificar al cotizar</mat-slide-toggle> 
                                    </p>
                                </div>
                            </div>
                            <!-- Final de la configuracion -->

                            <!-- Seccion de Valores Residuales-->
                            <span class="subtitle">Valores Residuales</span>
                            <div class="distribution-valor-recidual">
                                <div class="custom-form-field">
                                    <mat-form-field appearance="outline" style="width: 60px; margin-right: 10px;">
                                        <mat-label>12</mat-label>
                                    <input matInput [disabled]="!(puede_cambiar_residuales.includes(perfil) || usuario_puede_cambiar_configuracion.includes(id))" type="number" min="0"
                                    [(ngModel)]="Vendor.valores_residuales.ap12" name="residual_ap12"
                                    maxlength="2" >
                                    </mat-form-field>
                                </div>
                                <div class="custom-form-field">
                                    <mat-form-field appearance="outline" style="width: 60px; margin-right: 10px;">
                                        <mat-label>18</mat-label>
                                    <input matInput [disabled]="!(puede_cambiar_residuales.includes(perfil) || usuario_puede_cambiar_configuracion.includes(id))" type="number" min="0"
                                    [(ngModel)]="Vendor.valores_residuales.ap18" name="residual_ap18"
                                    maxlength="2">
                                    </mat-form-field>
                                </div>
                                <div class="custom-form-field">
                                    <mat-form-field appearance="outline" style="width: 60px; margin-right: 10px;">
                                        <mat-label>24</mat-label>
                                    <input matInput [disabled]="!(puede_cambiar_residuales.includes(perfil) || usuario_puede_cambiar_configuracion.includes(id))" type="number" min="0"
                                    [(ngModel)]="Vendor.valores_residuales.ap24" name="residual_ap24"
                                    maxlength="2">
                                    </mat-form-field>
                                </div>
                                <div class="custom-form-field">
                                    <mat-form-field appearance="outline" style="width: 60px; margin-right: 10px;">
                                        <mat-label>36</mat-label>
                                    <input matInput [disabled]="!(puede_cambiar_residuales.includes(perfil) || usuario_puede_cambiar_configuracion.includes(id))" type="number" min="0"
                                    [(ngModel)]="Vendor.valores_residuales.ap36" name="residual_ap36"
                                    maxlength="2">
                                    </mat-form-field>
                                </div>
                                <div class="custom-form-field">
                                    <mat-form-field appearance="outline" style="width: 60px; margin-right: 10px;">
                                        <mat-label>48</mat-label>
                                    <input matInput [disabled]="!(puede_cambiar_residuales.includes(perfil) || usuario_puede_cambiar_configuracion.includes(id))" type="number" min="0"
                                    [(ngModel)]="Vendor.valores_residuales.ap48" name="residual_ap48"
                                    maxlength="2">
                                    </mat-form-field>
                                </div>
                                <div class="custom-form-field">
                                    <mat-form-field appearance="outline" style="width: 60px; margin-right: 10px;">
                                        <mat-label>60</mat-label>
                                    <input matInput [disabled]="!(puede_cambiar_residuales.includes(perfil) || usuario_puede_cambiar_configuracion.includes(id))" type="number" min="0"
                                    [(ngModel)]="Vendor.valores_residuales.ap60" name="residual_ap60"
                                    maxlength="2">
                                    </mat-form-field>
                                </div>
                            </div>
                            <!-- Finde de Vlor Residual -->
                            <!-- Tasa Mensual -->
                             <span class="subtitle"> Tasas mensuales</span>
                             <div class="distribution-elment" style="margin-left: 30px;">
                                <div class="custom-form-field elemnt-row-tasa" style="width: 50%;">
                                    <mat-form-field appearance="outline" style="width: 90%; margin-left: 10px;">
                                        <mat-label>Arrendamiento Puro</mat-label>
                                    <input matInput [disabled]="!(puede_cambiar_tasa_ap.includes(perfil) || usuario_puede_cambiar_configuracion.includes(id))" type="number"
                                            [(ngModel)]="tasa_mensual_financiamiento" 
                                            (input)="ActualizarTasa(1)" />
                                    </mat-form-field>
                                </div>
                                <div class="custom-form-field elemnt-row-tasa" style="width: 50%;">
                                    <mat-form-field appearance="outline" style="width: 90%; margin-left: 10px;">
                                        <mat-label>Meses sin Intereses</mat-label>
                                        <input matInput [disabled]="!(puede_cambiar_tasa_msi.includes(perfil) || usuario_puede_cambiar_configuracion.includes(id))" type="number"
                                        [(ngModel)]="tasa_mensual_msi" 
                                        (input)="ActualizarTasa(2)" />
    
                                    </mat-form-field>
                                </div>
                             </div>
                             <!-- Fin tasa mensual -->
                             <div>
                                <span class="subtitle no-border">Comisión Vendor</span>
                                <div class="custom-form-field">
                                <mat-form-field appearance="outline" style="width: 50%; margin-left: 120px;">
                                    <mat-label>Comisión</mat-label>
                                    <input matInput
                                            name="comision_vendor" id="comision_vendor"
                                            mask="separator.2" thousandSeparator="," suffix="%"
                                            [disabled]="!(puede_cambiar_configuracion.includes(perfil) || usuario_puede_cambiar_configuracion.includes(id))"
                                            [(ngModel)]="Vendor.comision_vendor" 
                                            placeholder="0%" maxlength="3" />
                                </mat-form-field>
                            </div>
                             </div>
                             <!-- Comicion por apertura -->
                              <div>
                                <span class="subtitle no-border">Comisión por apertura</span>
                                <div class="custom-form-field" style="margin-left: 30px;">
                                    <div class="subtitle-comision">Arrendamiento Puro</div>
                                    <div>
                                        <mat-form-field appearance="outline"  style="width: 50%;">
                                            <mat-select name="commission_for_opening_type" id="commission_for_opening_type"
                                            [disabled]="!(puede_cambiar_tasa_ap.includes(perfil) || usuario_puede_cambiar_configuracion.includes(id))"
                                            [(ngModel)]="Vendor.commission_for_opening_type"
                                            (change)="ResetCommission()">
                                        <mat-option value="a">Monto $</mat-option>
                                        <mat-option value="p">Porcentaje %</mat-option>
                                        </mat-select>
                                        </mat-form-field>
                                        <input *ngIf="Vendor.commission_for_opening_type === 'a'" 
                                            name="commission_for_opening" id="commission_for_opening"
                                            mask="separator.2" thousandSeparator="," prefix="$ "
                                            [disabled]="!(puede_cambiar_configuracion.includes(perfil) || usuario_puede_cambiar_configuracion.includes(id))"
                                            [(ngModel)]="Vendor.commission_for_opening" style="width: 40%;  padding:15px;  padding-top: 20px; border: 0; margin-left: 10px;"
                                            placeholder="$ 0" maxlength="13" />
                                        <input *ngIf="Vendor.commission_for_opening_type === 'p'" 
                                        name="commission_for_opening" id="commission_for_opening"
                                        mask="separator.1" thousandSeparator="," suffix=" %"
                                        [disabled]="!(puede_cambiar_configuracion.includes(perfil) || usuario_puede_cambiar_configuracion.includes(id))"
                                        [(ngModel)]="Vendor.commission_for_opening" style="width: 40%;  padding:15px;  padding-top: 20px; border: 0; margin-left: 10px;"
                                        placeholder="0 %" maxlength="5" />
                                    </div>
                                    
                                </div>
                                <div>
                                    <div class="custom-form-field" style="margin-left: 30px;">
                                        <div class="subtitle-comision">Meses sin Intereses</div>
                                        <mat-form-field appearance="outline" style="width: 50%;">
                                            <mat-select name="commission_for_opening_type_msi"
                                                id="commission_for_opening_type_msi"
                                                [disabled]="!(puede_cambiar_tasa_ap.includes(perfil) || usuario_puede_cambiar_configuracion.includes(id))"
                                                [(ngModel)]="Vendor.commission_for_opening_type_msi"
                                                (change)="ResetCommissionMsi()"
                                                >
                                                <mat-option value="a">Monto $</mat-option>
                                                <mat-option value="p">Porcentaje %</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        
                                            <input *ngIf="Vendor.commission_for_opening_type_msi === 'a'" 
                                                name="commission_for_opening_msi" id="commission_for_opening_msi"
                                                mask="separator.2" thousandSeparator="," prefix="$ "
                                                [(ngModel)]="Vendor.commission_for_opening_msi"style="width: 40%;  padding:15px;  padding-top: 20px; border: 0; margin-left: 10px;"
                                                [disabled]="!(puede_cambiar_configuracion.includes(perfil) || usuario_puede_cambiar_configuracion.includes(id))"
                                                placeholder="$ 0" maxlength="13" />
                        
                                            <input *ngIf="Vendor.commission_for_opening_type_msi === 'p'" 
                                                name="commission_for_opening_msi" id="commission_for_opening_msi"
                                                mask="separator.1" thousandSeparator="," suffix=" %"
                                                [(ngModel)]="Vendor.commission_for_opening_msi" style="width: 40%;  padding:15px;  padding-top: 20px; border: 0; margin-left: 10px;"
                                                [disabled]="!(puede_cambiar_configuracion.includes(perfil) || usuario_puede_cambiar_configuracion.includes(id))"
                                                placeholder="0 %" maxlength="5" />
                                    </div>
                                    <div class="custom-form-field">
                                        <label></label>
                                    </div>
                                </div>
                              </div>
                              <div></div>
                            <div></div>
                    
                        
                        </div>
                        <!-- finCampos -->
                    </div>
                    <div style="margin-top: 40px; display: flex; justify-content: center;">
                        <div>Tabla CF MSI</div>
                       <!-- Mostrar los porcentajes arriba de la tabla -->
                       <div>
                        <h3>Tabla CF MSI</h3>
                        <table>
                          <thead>
                            <tr style="background-color: #AAD1F2; padding: 5px;">
                              <th>Plazo</th>
                              <th *ngFor="let porcentaje of porcentajes[0].porcentajes">{{ porcentaje }}%</th>

                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of porcentajes">
                              <td>{{ item.label }}</td>
                              <td *ngFor="let cf of pago_inicial.gracia0[item.plazo]; let i = index">
                                <input type="number" style="width: 60px;" [(ngModel)]="pago_inicial.gracia0[item.plazo][i]" />
                              </td>
                              <td>
                                <button (click)="eliminarPlazo(item.label)"><mat-icon mat-icon-button style="color:red;">delete</mat-icon></button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      
                        <!-- Agregar nuevo plazo -->
                        <div>
                          <label>Agregar Plazo: </label>
                          <div >
                            <select [(ngModel)]="nuevoPlazo">
                              <option *ngFor="let plazo of plazosFijos; let i = index" [value]="plazo">{{ plazo }}</option>
                            </select>
                          </div>
                          <button (click)="agregarPlazo()">Agregar Plazo</button>
                        </div>
                      
                        <!-- Agregar nuevo porcentaje -->
                        <div>
                          <label>Agregar Porcentaje: </label>
                          <input type="number" [(ngModel)]="nuevoPorcentaje" placeholder="Ej. 55" />
                          <button (click)="agregarPorcentaje()">Agregar Porcentaje</button>
                        </div>
                      
                        <!-- Botón para guardar -->
                        <button (click)="guardarDatos()">Guardar Datos</button>
                      </div>
                    </div>
                      <!-- Fin comicion por apertura -->
                    <!-- <input *ngIf="isVendor" type="submit" value="Guardar" id="btn_guardar_producto"> -->
                    <div
                        style="display: flex; justify-content: space-around; align-items: center; margin-bottom: 30px;">
                        <!-- <button *ngIf="!is_loading" id="btn_guardar_producto" class="btn_contra"
                            (click)="ResetPassword()"><mat-icon>autorenew</mat-icon><span>Reestablecer
                                contraseña</span></button>
                        <button id="btn_guardar_producto"
                            routerLink="/vendors"><mat-icon>person</mat-icon><span>Ver Vendors</span></button> -->
                        <button *ngIf="!is_loading && (puede_guardar.includes(perfil) || usuario_puede_cambiar_configuracion.includes(id))"
                            id="btn_guardar_producto"
                            (click)="GuardarVendor()"><mat-icon>save</mat-icon><span>Guardar</span></button>
                        <button *ngIf="!is_loading && puede_validar.includes(perfil)guardar_producto"
                            (click)="ValidarVendor()"><mat-icon>done</mat-icon><span>Validar
                                vendor</span></button>
                        <mat-spinner *ngIf="is_loading" diameter="30"></mat-spinner>
                    </div>

                    <!-- </form> -->
                </div>
            </div>
            <app-socials-footer style="width: 100%;"></app-socials-footer>
        </div>
    </div>
</app-sidenav-v2>