<app-sidenav-v2
[shortMenu]="true"
(openNotifications)="openNotifications()"
[commentsUnreadLength]="comments_unread.length"
>
        <div class="container">
            <div class="top">
                <div class="title"> <app-go-bak></app-go-bak> <span style="margin-left: 10px;"> Producto </span></div>
                <span>{{ tipo_evento }} producto</span>
            </div>
        
            <div class="box">
                
                <div class="product_form" style="margin-right: 20px;"> 
                    <form (ngSubmit)="GuardarProducto()">
                        <input [(ngModel)]="Product.id" type="hidden" name="id"/>
                        <input [(ngModel)]="Product.vendor_id" type="hidden" name="vendor_id"/>
                        <div id="product_header">
                            <div style="display: flex; flex-direction: column; align-items: center; margin-right: 20px;">
                                <div class="container-img">
                                    <div class="container-img-photo">
                                        <img id="product_no_image" [src]="image_preview" alt="Agregar imagen" *ngIf="image_preview == 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/no-photo.svg'">
                                        <img id="product_image" [src]="image_preview" alt="Agregar imagen"  *ngIf="image_preview != 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/no-photo.svg'">
                                    </div>
                                    
                                </div>
                                <div style="font-weight: 300; font-size: 12px; width: 65%; text-align: center;">Recuerda que la imagen debe estar en formato .jpg de 800 * 800px y con un peso máximo de 10 MB.</div>
                                <div class="container-input" *ngIf="isVendor" (click)="ClickFile()">
                                    <input type="file" accept=".jpg, .jpeg" name="product_image" id="product_image" class="inputfile inputfile-1" data-multiple-caption="{count} archivos seleccionados" (change)="ImagePreview()" />
                                    <label for="product_image">
                                        <img src="../../../../assets/icons/upload-degradated.png" style="margin-right: 3px;">
                                    <span class="iborrainputfile">Cargar la imagen</span>
                                    </label>
                                </div>
                                <app-msi-table *ngIf="show_msi" (costo_financiero)="GetCostoFinanciero($event, true)" [value]="costo_financiero"></app-msi-table>
                                
                            </div>
                            <div id="product_name">
                                
                                <div class="custom-form-field" [class.non-empty]="!isInputEmpty">
                                    <label>Nombre *</label>
                                    <input type="text" name="name" id="name" placeholder=" "         (ngModelChange)="checkInput(Product.name)" 
                                    [ngClass]="{'input-readonly': !isVendor}"   required [(ngModel)]="Product.name" [readonly]="!isVendor" style="width: 100%;">
                                </div>
                                <mat-error *ngIf="error && Product.name == ''">Campo obligatorio</mat-error>
                                <div class="custom-form-field" [class.non-empty]="!isInputEmpty">
                                    <label>Descripción *</label>
                                    <textarea name="description" id="description" rows="2" [readonly]="!isVendor" maxlength="350" (ngModelChange)="checkInput(Product.description)" 
                                    [ngClass]="{'input-readonly': !isVendor}"   required [(ngModel)]="Product.description" [readonly]="!isVendor" style="width: 103%; height: 50px;"></textarea>
                                </div>
                                <mat-error *ngIf="error && Product.description == ''">Campo obligatorio</mat-error>
                                <div class="custom-form-field" [class.non-empty]="!isInputEmpty">
                                    <label>Accesorios incluidos *</label>
                                    <textarea name="accessories" id="accessories" rows="2" [readonly]="!isVendor" maxlength="350" (ngModelChange)="checkInput(Product.accessories)" 
                                    [ngClass]="{'input-readonly': !isVendor}"   required [(ngModel)]="Product.accessories" [readonly]="!isVendor" style="width: 103%; height: 50px;"></textarea>
                                </div>
                                <mat-error *ngIf="error && !Product.accessories">Campo obligatorio</mat-error>
                                <div id="product_details">
                                    <div>
                                        <div class="custom-form-field" [class.non-empty]="!isInputEmpty">
                                            <label>Modelo *</label>
                                            <input name="model" id="model"  rows="2" [readonly]="!isVendor" maxlength="350" (ngModelChange)="checkInput(Product.model)" 
                                            [ngClass]="{'input-readonly': !isVendor}"   required [(ngModel)]="Product.model" [readonly]="!isVendor" style="width: 100%;">
                                            <mat-error *ngIf="error && !Product.model">Campo obligatorio</mat-error>

                                        </div>

                                        <div class="custom-form-field" [class.non-empty]="!isInputEmpty">
                                            <label>Año *</label>
                                            <input name="year" id="year"  rows="2" [readonly]="!isVendor" maxlength="350" (ngModelChange)="checkInput(Product.year)" 
                                            [ngClass]="{'input-readonly': !isVendor}"   required [(ngModel)]="Product.year" [readonly]="!isVendor" style="width: 100%;">
                                            <mat-error *ngIf="error && !Product.year">Campo obligatorio</mat-error>
                                        </div>

                                    </div>

                                    <div>
                                        <div class="custom-form-field" [class.non-empty]="!isInputEmpty">
                                            <label>Garantía *</label>
                                            <input name="warranty" id="warranty"  rows="2" [readonly]="!isVendor" maxlength="350" (ngModelChange)="checkInput(Product.warranty)" 
                                            [ngClass]="{'input-readonly': !isVendor}"   required [(ngModel)]="Product.warranty" [readonly]="!isVendor" style="width: 100%;">
                                            <mat-error *ngIf="error && !Product.warranty">Campo obligatorio</mat-error>
                                        </div>

                                        <div>

                                        </div>
                                    </div>

                                    <p>
                                        <mat-slide-toggle  [checked]="Product.edit"  (change)="Product.edit = !Product.edit" > Permitir modificar al cotizar</mat-slide-toggle>                                    
                                    </p>
                                    <div>
                                        
                                        <div class="custom-form-field" [class.non-empty]="!isInputEmpty">
                                            <label>Valor Factura a Lfu con IVA </label>
                                            <input  mask="separator.2" thousandSeparator="," prefix="$ " name="value" id="value" [readonly]="!isVendor" maxlength="350" (ngModelChange)="checkInput(Product.value)" 
                                            [ngClass]="{'input-readonly': !isVendor}"   required [(ngModel)]="Product.value" [readonly]="!isVendor" style="width: 100%;" (input)="GetCostoFinanciero(costo_financiero)">
                                        </div>
                                        <div class="custom-form-field" [class.non-empty]="!isInputEmpty">
                                            <label>Precio Lista con IVA</label>
                                            <input  mask="separator.2" thousandSeparator="," prefix="$ " name="customer_price" id="customer_price"  [readonly]="!isVendor" maxlength="350" (ngModelChange)="checkInput(Product.customer_price)" 
                                            [ngClass]="{'input-readonly': !isVendor}"   required [(ngModel)]="Product.customer_price" [readonly]="!isVendor" style="width: 100%;" >
                                        </div>
                                    </div>

                                    <p>
                                        <mat-slide-toggle  [checked]="show_msi"  (change)="show_msi = !show_msi; Product.suggested_customer_price = Product.customer_price" > Meses sin intereses</mat-slide-toggle>                                    

                                    </p>
                                    <div *ngIf="show_msi && costo_financiero && costo_financiero.plazos_seleccionados.length && (costo_financiero.disminuir_valor_factura || costo_financiero.aumentar_valor_factura)">
                                        Detalle costo financiero
                                        <table class="costo_financiero" style="width: 100%; padding: 0; border-spacing: 0;">
                                            <thead>
                                                <tr><th>Plazo</th><th>% inicial</th><th>C.F.</th><th>Total</th></tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let row of costo_financiero.plazos_seleccionados">
                                                    <td>{{row.plazo}}</td><td>{{row.porcentaje}}%</td><td>{{row.value}}%</td><td>{{ (costo_financiero.disminuir_valor_factura ? (Product.value * 100 / (100 - row.value)) * (row.value / 100) :  Product.customer_price * (row.value /100)) | currency:'MXN'}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div>
                                        <div class="custom-form-field" [class.non-empty]="!isInputEmpty">
                                            <label>Precio Minimo con IVA</label>
                                            <input  mask="separator.2" thousandSeparator="," prefix="$ " name="suggested_customer_price" id="suggested_customer_price" [readonly]="!isVendor || show_msi" maxlength="350" (ngModelChange)="checkInput(Product.suggested_customer_price)" 
                                            [ngClass]="{'input-readonly': !isVendor}"   required [(ngModel)]="Product.suggested_customer_price" style="width: 100%;" (change)="ValidarPrecioMinimo()">
                                        </div>
                                        <div class="custom-form-field" [class.non-empty]="!isInputEmpty">
                                            <label>Precio Minimo con IVA</label>
                                            <input  name="currency" id="currency" [readonly]="!isVendor || vendor.country != 'MX'"
                                            [ngClass]="'input-readonly'"   required [readonly]="true" style="width: 100%;" *ngIf="!isVendor || vendor.country != 'MX'" value="USD">
                                            <select [ngClass]="{'input-readonly': !isVendor}" [(ngModel)]="Product.currency" type="text" name="currency" id="currency" *ngIf="isVendor && vendor.country == 'MX'" style="width: 107%; height: 34px; border-radius: 16px;">
                                                <option value="MXN">MXN</option>
                                                <option value="USD">USD</option>
                                                <option value="EUR">EUR</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <!-- <input *ngIf="isVendor" type="submit" value="Guardar" id="btn_guardar_producto"> -->
                                <div class="btn_actions">
                                    <button id="btn_ver_productos" routerLink="/productos/admin"><mat-icon>view_list</mat-icon><span>Ver productos</span></button>
                                    <button *ngIf="isVendor && !is_loading" type="submit" id="btn_guardar_producto" >
                                        <mat-icon>save</mat-icon><span>Guardar</span>
                                    </button>
                                    <mat-spinner *ngIf="is_loading" diameter="30" style="margin-right: 50px;"></mat-spinner>
                                </div>
                            </div>

                        </div>
                    
                    </form>
                </div>




            </div>
            <app-socials-footer style="width: 100%;"></app-socials-footer>
        </div>
</app-sidenav-v2>

