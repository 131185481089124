<div class="documento" *ngIf="show">
    <div class="container">
        <div class="nombre_documento">
            <span>{{ documento.nombre_documento}} <b *ngIf="documento.requerido">*</b></span>
            <span class="btn-direccion" *ngIf="documento.tipo_documento == 12" (click)="IndicarDireccion()">Indicar dirección</span>
        <span>
            <svg *ngIf="documento.descripcioncompleta" (click)="info()" xmlns="http://www.w3.org/2000/svg" width="17.328" height="17.328" viewBox="0 0 17.328 17.328"><defs><style>.info_icon{fill:#fff; stroke: #3F4A57;}</style></defs><path class="info_icon" d="M10.664,2a8.664,8.664,0,1,0,8.664,8.664A8.664,8.664,0,0,0,10.664,2Zm.866,13H9.8V13.263H11.53Zm0-3.466H9.8v-5.2H11.53Z" transform="translate(-2 -2)"/></svg>
        </span>
        <div class="options">
            <div>
                {{ documento.status == 0 ? 'Por cargar' : documento.status == 1 ? 'En revisión' : documento.status == 2 ? 'Actualizar' : documento.status == 3 ? 'Validado' : '' }}
                <p [ngClass]="{'completed': documento.status == 3, 'inprocess': documento.status == 1, 'atencion': documento.status == 2}"></p>
            </div>
            <div class="actions">
                <svg [ngClass]="{'disabled': documento.status == 3 || documento.status == 1 }" (click)="upload()" xmlns="http://www.w3.org/2000/svg" width="21.261" height="28.828" viewBox="0 0 21.261 28.828"><defs><style>.upload{fill:#00a5da;}</style></defs><path class="upload" d="M17.631,3.594l-.849.812L8.513,12.674l1.7,1.7,6.238-6.238V27.7h2.362V8.134l6.238,6.238,1.7-1.7L18.48,4.406ZM7,30.059v2.362H28.261V30.059Z" transform="translate(-7 -3.594)"/></svg>
                <!-- <svg [ngClass]="{'disabled': (!documento.url.length && (documento.status != 1)) || (documento.status == 3 && documento.tipo_documento == 3)}" (click)="verDocumento()" style="height: 20px; width:50px;" xmlns="http://www.w3.org/2000/svg" width="80" height="10" viewBox="0 0 21.261 30.828"><defs><style>.a,.d{fill:none;}.a{stroke:#3ca2d6;stroke-width:2.5px;}.b{fill:#fff;stroke:#707070;}.c{stroke:none;}</style></defs><g transform="translate(-0.594 -22.973)"><path class="a" d="M22.316,4.5A22.918,22.918,0,0,0,1,19.034a22.9,22.9,0,0,0,42.632,0c-1.281-3.25-.7-5.037-6.026-8.671S28.3,4.5,22.316,4.5Zm0,24.223A9.689,9.689,0,1,1,32,19.034,9.693,9.693,0,0,1,22.316,28.723Zm0-15.5a5.813,5.813,0,1,0,5.813,5.813A5.806,5.806,0,0,0,22.316,13.22Z" transform="translate(0.938 19.723)"/><g class="b" transform="translate(2796.605 1649.469)"><rect class="c" width="1852" height="1104"/><rect class="d" x="0.5" y="0.5" width="1851" height="1103"/></g></g></svg> -->
                <svg [ngClass]="{'disabled': !documento.url.length}" (click)="verDocumento()" style="height: 20px; width:50px;" xmlns="http://www.w3.org/2000/svg" width="80" height="10" viewBox="0 0 21.261 30.828"><defs><style>.a,.d{fill:none;}.a{stroke:#3ca2d6;stroke-width:2.5px;}.b{fill:#fff;stroke:#707070;}.c{stroke:none;}</style></defs><g transform="translate(-0.594 -22.973)"><path class="a" d="M22.316,4.5A22.918,22.918,0,0,0,1,19.034a22.9,22.9,0,0,0,42.632,0c-1.281-3.25-.7-5.037-6.026-8.671S28.3,4.5,22.316,4.5Zm0,24.223A9.689,9.689,0,1,1,32,19.034,9.693,9.693,0,0,1,22.316,28.723Zm0-15.5a5.813,5.813,0,1,0,5.813,5.813A5.806,5.806,0,0,0,22.316,13.22Z" transform="translate(0.938 19.723)"/><g class="b" transform="translate(2796.605 1649.469)"><rect class="c" width="1852" height="1104"/><rect class="d" x="0.5" y="0.5" width="1851" height="1103"/></g></g></svg>
                <svg [ngClass]="{'disabled': !documento.url.length}" (click)="download()" style="height: 20px; width:auto;" xmlns="http://www.w3.org/2000/svg" width="21.262" height="26.466" viewBox="0 0 21.262 26.466"><defs><style>.upload{fill:#3ca2d6;}</style></defs><path class="upload" d="M17.631,32.422l-.849-.812L8.513,23.342l1.7-1.7,6.238,6.238V8.318h2.362V27.882l6.238-6.238,1.7,1.7L18.48,31.61ZM7,5.956H7Z" transform="translate(-7 -5.956)"/></svg>
                <svg [ngClass]="{'disabled': !documento.comentarios.length, 'atencion': documento.comentarios.length}" (click)="verComentarios()" style="height: 20px; width:auto;" xmlns="http://www.w3.org/2000/svg" width="37.074" height="32.908" viewBox="0 0 37.074 32.908"><defs><style>.comment{fill:#b9b8b8;}</style></defs><path class="comment" d="M3,5V30.667h7.13v7.241l9.057-7.241H40.074V5ZM5.852,7.852h31.37V27.815H18.184l-5.2,4.161V27.815H5.852Zm7.13,7.13a2.852,2.852,0,1,0,2.852,2.852A2.85,2.85,0,0,0,12.981,14.981Zm8.556,0a2.852,2.852,0,1,0,2.852,2.852A2.85,2.85,0,0,0,21.537,14.981Zm8.556,0a2.852,2.852,0,1,0,2.852,2.852A2.85,2.85,0,0,0,30.093,14.981Z" transform="translate(-3 -5)"/></svg>
            </div>
        </div>
    </div>

    <div class="container validacion" *ngIf="validar_documento">
        <span>Validación</span>
        <div>
            <mat-spinner *ngIf="aceptando" diameter="30"></mat-spinner>
            <span *ngIf="!aceptando" (click)="onAceptar()" [ngClass]="{'disabled': documento.status != 1 || (documento.tipo_documento == 3 && solicitante == true), 'acepted': documento.status == 3}"><mat-icon>done</mat-icon></span>
            <mat-spinner *ngIf="rechazando" diameter="30"></mat-spinner>
            <span *ngIf="!rechazando" (click)="onRechazar()" [ngClass]="{'disabled': documento.status != 1 || (documento.tipo_documento == 3 && solicitante == true), 'attention': documento.status == 2}"><mat-icon>clear</mat-icon></span>
        </div>
    </div>
    <div class="container cancel-doc" *ngIf="validar_documento || cancelar_documento">
        <span style="text-align: center; cursor: default!important;">Cancelar Documento</span>
        <div>
            <mat-spinner *ngIf="rechazando" diameter="30"></mat-spinner>
            <span *ngIf="!rechazando" (click)="onRechazarDoc()" [ngClass]="{'disabled': documento.status != 3, 'attention': documento.status == 2}"><mat-icon>clear</mat-icon></span>
        </div>
    </div>
</div>