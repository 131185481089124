import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IndicarDireccionComponent } from 'src/app/_dialogs/indicar-direccion/indicar-direccion.component';
import { UserService } from 'src/app/_services';

@Component({
  selector: 'app-documento',
  templateUrl: './documento.component.html',
  styleUrls: ['./documento.component.scss']
})
export class DocumentoComponent implements OnInit {
  @Input() documento
  @Input() index
  @Input() solicitante
  @Input() show = true

  @Output() VerInfo = new EventEmitter()
  @Output() Upload = new EventEmitter()
  @Output() VerDocumento = new EventEmitter()
  @Output() VerComentarios = new EventEmitter()
  @Output() Aceptar = new EventEmitter()
  @Output() Rechazar = new EventEmitter()

  folio = null
  aceptando = false
  rechazando = false
  perfil = null 

  validar_documento
  cancelar_documento = false

  clientDetails = null

  constructor(private dialog: MatDialog,
    private userService:UserService) { }

  ngOnInit(): void {
    console.log(this.documento);
    this.perfil = JSON.parse(localStorage.getItem('currentUser')).perfil
    this.clientDetails = JSON.parse(localStorage.getItem('client_details'))

    this.folio = this.clientDetails.folio

    this.aceptando = false
    this.rechazando = false

    this.validar_documento = [4,14,15].includes(Number(this.perfil))
    this.cancelar_documento = [10].includes(Number(this.perfil))

    console.log(this.perfil, this.validar_documento);
    
  }

  info(): any{
    if( !this.userService.alertService.isOnline() ) return
    this.VerInfo.emit(this.documento.descripcioncompleta)
  }

  upload(): any {
    if( !this.userService.alertService.isOnline() ) return
    console.log(this.index);
    if( this.documento.status == 1 || this.documento.status == 3) return
    this.Upload.emit(this.index)
  }
  verDocumento(): any {
    console.log('this.documento.status :', this.documento.status);
    console.log('this.documento.tipo_documento :', this.documento.tipo_documento);
    if( !this.userService.alertService.isOnline() ) return
    console.log(this.index);
    console.log(!this.documento.url);
    
    console.log("Documento: ", this.documento);
    if( this.documento.status == 0 ) return
    if( this.documento.tipo_documento == 3 && this.documento.status == 3 && this.perfil == 3 ) return
    if( this.documento.tipo_documento == 3 && this.documento.status == 3  ) return
    if( this.documento.tipo_documento != 3 || this.documento.tipo_documento != 9 ) return this.VerDocumento.emit(this.index)
    if( !this.documento.url.length ) return
    this.VerDocumento.emit(this.index)
    
  }

  verComentarios(): any {
    if( !this.userService.alertService.isOnline() ) return
    if( !this.documento.comentarios.length ) return
    this.VerComentarios.emit(this.index)
  }

  onAceptar(){
    if( !this.userService.alertService.isOnline() ) return
    if( this.documento.status != 1 || this.documento.tipo_documento == 3 && this.solicitante == true ) return 
    this.aceptando = true
    this.Aceptar.emit({
      index: this.index,
      status: true
    })
  }

  onRechazar(){
    if( !this.userService.alertService.isOnline() ) return
    if( this.documento.status != 1 ) return 
    this.rechazando = true
    this.Rechazar.emit({
      index: this.index,
      status: false
    })
  }

  public onRechazarDoc(): void {
    if (!this.userService.alertService.isOnline()) { return; }
    // if ( this.documento.tipo_documento == 3 ) { return; }
    this.rechazando = true;
    this.Rechazar.emit({
      index: this.index,
      status: false
    });
  }

  download() {
    if( !this.userService.alertService.isOnline() ) return
    if( this.documento.url.length )
      window.open(this.documento.url, "downlaod")
  }

  IndicarDireccion() {
    console.log('indicar dirección', this.folio);
    this.dialog.open(IndicarDireccionComponent, {
      width: '600px',
      data: {folio: this.folio}
    })
  }
}
