<div class="container-charging" *ngIf="!loading && FiltrarVendedores.length === 0">
    <div class="text">
        <p>Cargando vendors...</p>
    </div>
    <div class="container-progress">
        <img src="../../../../assets/images/letter-u.svg"/>
        <!-- <mat-progress-bar mode="indeterminate"></mat-progress-bar> -->
        <div class="progress">
            <div class="progress-value"></div>
          </div>
    </div>
  </div>
  <p *ngIf="loading && FiltrarVendedores.length == 0">Aún no hay vendedores que mostrar</p>
    <div *ngIf="!loading">
        <div class="btn-container" style="background-color: bisque;">
            <button class="btn-newvendor" style="margin-top: 16px;" (click)="AgregarVendedor()">Agregar vendedor</button>
        </div>
        <div class="table-vendedores">
            <table class="table-vendedores" [cellSpacing]="0" [cellPadding]="0" *ngIf="VendedoresFiltro.length">
                <thead>
                    <tr>
                        <th style="max-width: 15px;">N.</th>
                        <th>Nombre</th>
                        <th>Correo Electrónico</th>
                        <th>Teléfono</th>
                        <th>1er Acceso</th>
                        <th>Activo</th>
                        <th>Opciones</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let Vendedor of VendedoresFiltro; let i = index">
                        <td>{{i +1}}</td>
                        <td>{{Vendedor.nombres}} {{Vendedor.apellido_parterno}} {{Vendedor.apellido_materno}}</td>
                        <td>{{Vendedor.email}}</td>
                        <td>{{Vendedor.phone.length ? Vendedor.phone : ' - '}}</td>
                        <td style="text-align: center;">{{Vendedor.primerAcceso ? 'Sí' : 'No'}}</td>
                        <td>{{Vendedor.status ? 'Sí' : 'No'}}</td>
                        <td id="options">
                            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button (click)="Editar(Vendedor)" class="btn-menu">
                                    <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/editar-producto.svg">
                                    <span>Editar</span>
                                </button>
                                <button (click)="ResetPassword(Vendedor)" class="btn-menu">
                                    <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/resetearPassword.svg">
                                    <span>Restablecer Contraseña</span>
                                </button>
                                <button (click)="ActivarVendedor(Vendedor)" class="btn-menu"> <!--(click)="ActivarVendor(Vendor)"-->
                                    <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/duplicar-producto.svg">
                                    <span>Activar Vendedor</span>
                                </button>
                                <button (click)="DesactivarVendedor(Vendedor)" class="btn-menu">
                                    <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/desactivar.svg">
                                    <span>Desactivar Vendedor</span>
                                </button>
                                <!-- <button class="btn-menu" (click)="EliminarVendedor(Vendedor)">
                                    <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/eliminar-producto.svg">
                                    <span>Eliminar Vendedor</span>
                                </button> -->
                            </mat-menu>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>